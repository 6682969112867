import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    role: null,
  },
  reducers: {
    setRole: (state, action) => {
      state.role = action.payload;
    },
    removeRole: (state, action) => {
      state.role = null;
    },
  },
});

export const userReducer = userSlice.reducer;
export const { setRole, removeRole } = userSlice.actions;
