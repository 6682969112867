import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import { connect } from "react-redux";

// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes";

// Import all middleware
import Authmiddleware from "./routes/route";

// layouts Format
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";
import { Constants } from "helpers/constant";
import { getSecureToken, isUserLoggedIn, verifyUser } from "helpers/utils";
import { Spinner } from "reactstrap";
import { useUserSelector, useUserDispatch } from "index";
import Logout from "pages/Authentication/Logout";
import { removeRole, setRole } from "redux/roleSlice";
import PagesComingsoon from "pages/Utility/pages-comingsoon";

// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper";

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// };

// init firebase backend
// initFirebaseBackend(firebaseConfig);

const App = (props) => {
  const user = useUserSelector((state) => state.role);
  const dispatch = useUserDispatch();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (isUserLoggedIn()) {
      setIsLoading(true);
      verifyUser()
        .then((role) => {
          dispatch(setRole(role));
          setIsLoading(false);
        })
        .catch((error) => {
          console.log("Error", error);
          dispatch(removeRole());
          setIsLoading(false);
        });
    }
  }, [isUserLoggedIn]);
  if (isLoading) {
    return (
      <div id="preloader">
        <div id="status">
          <div className="spinner-chase">
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <React.Fragment>
      <Router basename="/#">
        <Switch>
          {publicRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              exact
            />
          ))}
          {user &&
          authProtectedRoutes.filter((route) =>
            route?.allowedFor?.includes(user)
          ).length > 0 ? (
            authProtectedRoutes
              .filter((route) => route?.allowedFor?.includes(user))
              .map((route, idx) => (
                <Authmiddleware
                  path={route.path}
                  layout={HorizontalLayout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={true}
                  exact
                />
              ))
          ) : (
            <Logout
              error={{
                message: "You don't have sufficient access",
                title: "Unauthorized",
              }}
            />
          )}
          <Route path={"*"} exact component={PagesComingsoon} />
        </Switch>
      </Router>
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, null)(App);
