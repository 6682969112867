import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { isEmpty, map } from "lodash";
import * as moment from "moment";
import {
  Badge,
  Button,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Component
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";

//Import Image
import images from "assets/images";
import companies from "assets/images/companies";
import BLIV_Partner from "assets/images/logo-small-nobg_new.png";

import * as url from "service/url_helper";
import { ApiServices } from "service/apiServices.js";
import { getSecureToken } from "helpers/utils.js";
import { Constants } from "helpers/constant.js";

const PropertyList = (props) => {
  //meta title
  document.title = "Product List | Numerolution";
  let userData = getSecureToken(Constants.KEY_USER_DATA);
  const [product, setProject] = useState();
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [productList, setProductList] = useState([]);

  //delete order
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (product) => {
    setProject(product);
    setDeleteModal(true);
  };

  const handleDeleteOrder = () => {
    dispatch(onDeleteProject(product));
    setDeleteModal(false);
  };

  const getProperties = () => {
    try {
      let uri = url.GET_ALL_PRODUCTS;
      ApiServices.callServiceGet(uri)
        .then((response) => {
          if (response?.result?.type === "success") {
            setProductList(response.result.data);
          }
        })
        .catch((error) => {
          console.log("GET_PROPERTIES_BY_PARTNERID API call Error", error);
        });
    } catch (error) {
      console.log("GET_PROPERTIES_BY_PARTNERID API Function Error", error);
    }
  };
  useEffect(() => {
    getProperties();
  }, []);

  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row className="d-flex justify-content-between align-items-center w-100 m-0 mb-2">
            <Col className="p-0">
              <h5>Product List</h5>
            </Col>
            <Col className="d-flex justify-content-end align-items-end p-0">
              <Button
                type="button"
                color="primary"
                className="btn"
                onClick={() => props?.history?.push("/product/add")}
              >
                <i className="mdi mdi-plus-circle-outline me-1" />
                Add New Product
              </Button>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <div>
                <div className="table-responsive">
                  <Table className="product-list-table table-nowrap align-middle table-borderless">
                    <thead>
                      <tr>
                        <th scope="col">Product Name</th>
                        <th scope="col">Price</th>
                        <th scope="col">Color</th>
                        <th scope="col">Base Material</th>
                      </tr>
                    </thead>
                    <tbody>
                      {map(productList, (product, index) => (
                        <tr key={index}>
                          <td>
                            <h5 className="text-truncate font-size-14">
                              <Link
                                to={{
                                  pathname: `/product/detail/${product._id}`,
                                }}
                                className="text-primary"
                              >
                                {product.productName}
                              </Link>
                            </h5>
                            {/* <p className="text-muted mb-0">
                              {product.propertyCity}
                            </p> */}
                          </td>
                          <td>
                            {product?.price &&
                              Object.keys(product.price).map(
                                (currency, index) => {
                                  return (
                                    <h6 key={currency + index}>
                                      <strong>{currency}</strong>:{" "}
                                      {product.price[currency]}
                                    </h6>
                                  );
                                }
                              )}
                          </td>
                          <td> {product?.specification?.type}</td>
                          <td> {product?.specification?.color}</td>
                          <td> {product?.specification?.baseMaterial}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>

          {/* <Row>
            <Col xs="12">
              <div className="text-center my-3">
                <Link to="#" className="text-success">
                  <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                  Load more
                </Link>
              </div>
            </Col>
          </Row> */}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(PropertyList);
