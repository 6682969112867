import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { isEmpty, map } from "lodash";
import * as moment from "moment";
import {
  Badge,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Button,
  Row,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

import * as url from "service/url_helper";
import { ApiServices } from "service/apiServices.js";
import { getSecureToken, DeleteModal } from "helpers/utils.js";
import { Constants } from "helpers/constant.js";
import PDFUserTable from "../Pdf/PDFUserTable";
import toastr from "toastr";
import { useParams } from "react-router-dom";
import "toastr/build/toastr.min.css";

const AffiliateUserList = (props) => {
  //meta title
  toastr.options = {
    positionClass: "toast-top-right",
    timeOut: 1200,
    extendedTimeOut: 1000,
    closeButton: true,
    progressBar: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: "swing",
    hideEasing: "swing",
    showMethod: "fadeIn",
    hideMethod: "fadeOut",
    showDuration: 300,
    hideDuration: 500,
  };
  document.title = "Affiliate User List | Numerolution";
  const [userList, setUserList] = useState([]);
  const [exportingData, setExportingData] = useState([]);
  const capitalizeFirstLetter = (string) => {
    if (!string || string?.length == 0) return "N/A";
    return string?.charAt(0).toUpperCase() + string?.slice(1);
  };
  const columns = [
    {
      name: <span className="fw-bold font-size-14">Name</span>,
      selector: (row) => (
        <p className="text-capitalize text-truncate font-size-14 text-dark fw-bold mb-0">
          {capitalizeFirstLetter(row.name) ?? "N/A"}
        </p>
      ),
      sortable: true,
    },
    {
      name: <span className="fw-bold font-size-14">Mobile</span>,
      selector: (row) => (row.mobileNumber ? row.mobileNumber : "N/A"),
      sortable: true,
    },
    {
      name: <span className="fw-bold font-size-14">Discount(%)</span>,
      selector: (row) => (row.discount ? row.discount : "N/A"),
      sortable: true,
    },
    {
      name: <span className="fw-bold font-size-14">Discount Code</span>,
      selector: (row) => (row.discountCode ? row.discountCode : "N/A"),
      sortable: true,
    },
    {
      name: <span className="fw-bold font-size-14">Status</span>,
      selector: (row) => (
        <div className="square-switch">
          <input
            type="checkbox"
            id="square-switch1"
            switch="none"
            checked={row.isAffiliate}
            onChange={async (e) => await updateAffiliate(row)}
          />
          <label
            className="mb-0"
            htmlFor="square-switch1"
            id="square-switch-filter"
            data-on-label="Active"
            data-off-label=""
          />
        </div>
      ),
      sortable: false,
    },
    {
      name: <span className="fw-bold font-size-14">Actions</span>,
      selector: (row) => (
        <div className="d-inline-flex w-100 align-self-center align-items-center gap-1">
          {row.isAffiliate && (
            <button
              onClick={() => props.history.push(`/affiliate/edit/${row._id}`)}
              className="dropdown-item py-1 px-1"
            >
              <i className="mdi mdi-pencil  text-success font-size-16 me-0"></i>
            </button>
          )}
          <button
            onClick={async () => await DeleteModal(row, deleteAffiliate)}
            className="dropdown-item py-1 px-1"
          >
            <i className="mdi mdi-trash-can font-size-16 text-danger me-0"></i>
          </button>
        </div>
      ),
      sortable: false,
    },
  ];

  function convertArrayOfObjectsToCSV() {
    let newArray = [...exportingData].map((item) => {
      let newItem = {
        "User Name": item.name,
        "Mobile Number": item.mobileNumber,
        "Discount(%)": item.discount,
        "Discount Code": item.discountCode,
        Status: item.isAffiliate ? "Active" : "Inactive",
      };
      return newItem;
    });
    let result;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(newArray[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    newArray.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }
  function downloadCSV() {
    if (exportingData?.length <= 0) return toastr.error("No data", "Failed");
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV();
    if (csv == null) return;

    const filename = `AFFILIATE-USERS-${moment(Date.now()).format(
      "DD-MM-YYYY-HH-mm-ss"
    )}.csv`;

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }
  const onClickDelete = (product) => {
    setProject(product);
    setDeleteModal(true);
  };

  const handleDeleteOrder = () => {
    setDeleteModal(false);
  };

  const getAffiliateUsers = async () => {
    try {
      let uri = url.GET_ALL_AFFILIATE;
      await ApiServices.callServiceGet(uri)
        .then((response) => {
          if (response?.result?.type === "success") {
            setUserList(response.result.data);
            setExportingData(response.result.data);
          }
        })
        .catch((error) => {
          console.log("GET_PROPERTIES_BY_PARTNERID API call Error", error);
        });
    } catch (error) {
      console.log("GET_PROPERTIES_BY_PARTNERID API Function Error", error);
    }
  };
  async function updateAffiliate(user) {
    try {
      let uri = url.UPDATE_AFFILIATE + user._id;
      let data = {
        isAffiliate: !user.isAffiliate,
      };
      await ApiServices.callServicePutWithBodyData(uri, data)
        .then((response) => {
          if (response?.result?.type === "success") {
            toastr.success("Status Updated Successfully", "Success");
            getAffiliateUsers();
          }
        })
        .catch((error) => {
          console.log("updateAffiliate API call Error", error);
        });
    } catch (error) {
      console.log("updateAffiliate API Function Error", error);
    }
  }
  async function deleteAffiliate(user) {
    try {
      let uri = url.UPDATE_AFFILIATE + user._id;
      await ApiServices.callServiceDelete(uri)
        .then((response) => {
          if (response?.result?.type === "success") {
            toastr.success("User Deleted Successfully", "Success");
            getAffiliateUsers();
          }
        })
        .catch((error) => {
          console.log("deleteAffiliate API call Error", error);
        });
    } catch (error) {
      console.log("deleteAffiliate API Function Error", error);
    }
  }
  useEffect(() => {
    getAffiliateUsers();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row className="d-flex bg-white p-2 justify-content-between align-items-center w-100 m-0 mb-2">
            <Col className="p-0 d-flex align-items-center ">
              <h5 className="mb-0 fw-bold">Affiliate User List</h5>
            </Col>

            <Col className="d-flex justify-content-end align-items-center p-0 d-print-none">
              <Button
                type="button"
                color="primary"
                className="btn"
                onClick={() => props.history.push("/affiliate/add")}
              >
                <i className="fa fa-plus me-2" />
                Add Affiliate User
              </Button>
              <Button
                type="button"
                color="success"
                className="btn ms-2"
                onClick={() => window.print()}
              >
                <i className="fa fa-print me-2" />
                Print All Data
              </Button>
              <Button
                type="button"
                color="success"
                className="btn ms-2"
                onClick={() => downloadCSV()}
              >
                <i className="fas fa-file-csv me-2" />
                Export CSV
              </Button>
            </Col>
          </Row>
          <Row className="bg-white">
            <Col lg="12" className="px-0 py-2">
              {userList.length > 0 ? (
                <PDFUserTable
                  {...props}
                  columns={columns}
                  setExportingData={setExportingData}
                  userList={userList}
                />
              ) : (
                <Row>
                  <Col xs="12">
                    <div className="text-center my-3">
                      <p to="#" className="text-primary">
                        <i className="mdi mdi-alert-circle-outline me-2" />
                        No data
                      </p>
                    </div>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(AffiliateUserList);
