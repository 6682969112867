import React, { useState, useEffect, useMemo } from "react";
import DataTable from "react-data-table-component";
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  UncontrolledDropdown,
} from "reactstrap";
import { Link } from "react-router-dom";
import * as url from "service/url_helper";
import { ApiServices } from "service/apiServices";
import { Constants } from "helpers/constant.js";
import * as moment from "moment";

export default function PDFUserTable(props) {
  const { userList, reset, columns } = props;

  return (
    <DataTable
      key={reset}
      columns={columns}
      data={userList}
      onSort={(column, order, rows) => {
        if (props.setExportingData) {
          props.setExportingData(rows);
        }
      }}
      pagination
      paginationComponentOptions={{
        noRowsPerPage: true,
      }}
      paginationPerPage={100}
    />
  );
}
