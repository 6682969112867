import PropTypes from "prop-types";
import React, { useEffect } from "react";

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap";

import { withRouter, Link } from "react-router-dom";

// Formik validation
import { useFormik } from "formik";
import { loginSchema } from "helpers/validationSchemas";

//Social Media Imports
// import { GoogleLogin } from "react-google-login";
// import TwitterLogin from "react-twitter-auth"
// import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

// import images
import profile from "assets/images/profile-img.png";
// import logo from "assets/images/logo.svg";
import BLIV_Partner from "assets/images/logo-small-nobg_new.png";
import * as url from "../../service/url_helper";

//Import config
import { ApiServices } from "service/apiServices";
import { isUserLoggedIn, setSecureToken } from "helpers/utils";
import { Constants } from "helpers/constant";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { authProtectedRoutes } from "routes";
import { isEmpty } from "lodash";
import { useUserDispatch } from "index";
import { setRole } from "redux/roleSlice";

const Login = (props) => {
  toastr.options = {
    positionClass: "toast-top-right",
    timeOut: 1200,
    extendedTimeOut: 1000,
    closeButton: true,
    progressBar: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: "swing",
    hideEasing: "swing",
    showMethod: "fadeIn",
    hideMethod: "fadeOut",
    showDuration: 300,
    hideDuration: 500,
  };

  const dispatch = useUserDispatch();

  //meta title
  document.title = "Login | Numerolution";

  const onSubmit = (values) => {
    try {
      let uri = url.LOGIN_ADMIN;
      let reqObj = {
        mobileNumber: values?.mobileNumber,
        password: values?.password,
      };
      ApiServices.callServicePostWithBodyData(uri, reqObj)
        .then((response) => {
          if (
            response?.result?.type === Constants.SUCCESS &&
            (response.result?.user?.isAdmin ||
              response.result?.user?.isAffiliate)
          ) {
            setSecureToken(Constants.KEY_USER_TOKEN, response?.result?.token);
            setSecureToken(Constants.KEY_USER_DATA, response?.result?.user);
            const role = response.result?.user?.isAdmin ? "admin" : "affiliate";
            dispatch(setRole(role));
            toastr.success(
              response?.result?.message ? response.result.message : "",
              "Success"
            );

            const authorizedRoutes = authProtectedRoutes.filter((route) =>
              route?.allowedFor?.includes(role)
            );
            setTimeout(() => {
              if (isEmpty(authorizedRoutes)) {
                toastr.error(
                  "You don't have sufficient access",
                  "Unauthorized"
                );
                props.history.push("/logout");
                return;
              }
              props.history.push(authorizedRoutes[0].path);
            }, 1500);
          } else {
            toastr.error(
              response?.result?.message
                ? response.result.message
                : "Error Occured",
              "Failed"
            );
          }
        })
        .catch((err) => console.log("catch LOGIN Partner API call", err));
    } catch (error) {
      console.log("catch LOGIN Partner error", error);
    }
  };

  useEffect(() => {
    if (isUserLoggedIn()) {
      props.history.push("/dashboard");
    }
  }, []);
  const formik = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      mobileNumber: "",
      password: "",
    },
    validationSchema: loginSchema,
    onSubmit,
  });

  const { values, touched, errors, handleChange, handleBlur, handleSubmit } =
    formik;

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to continue to Numerolution.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={BLIV_Partner}
                            alt=""
                            //className="rounded-circle"
                            height="40"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit();
                        return false;
                      }}
                    >
                      {/* {error ? <Alert color="danger">{error}</Alert> : null} */}

                      <div className="mb-3">
                        <Label className="form-label">Mobile Number</Label>
                        <Input
                          onInput={(e) =>
                            (e.target.value = e.target.value.slice(0, 10))
                          }
                          maxLength={10}
                          name="mobileNumber"
                          className="form-control"
                          placeholder="Enter mobile number"
                          type="number"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.mobileNumber || ""}
                          invalid={
                            touched.mobileNumber && errors.mobileNumber
                              ? true
                              : false
                          }
                        />
                        {touched.mobileNumber && errors.mobileNumber ? (
                          <FormFeedback type="invalid">
                            {errors.mobileNumber}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-4">
                        <Label className="form-label">Password</Label>
                        <Input
                          name="password"
                          value={values.password || ""}
                          type="password"
                          placeholder="Enter Password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          invalid={
                            touched.password && errors.password ? true : false
                          }
                        />
                        {touched.password && errors.password ? (
                          <FormFeedback type="invalid">
                            {errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className=" mb-3 text-center">
                        <Link
                          to={{
                            pathname: "/forgot-password",
                            state: { mobileNumber: values?.mobileNumber },
                          }}
                          className="text-muted"
                        >
                          <i className="mdi mdi-lock me-1" />
                          Forgot your password?
                        </Link>
                      </div>

                      <div className="mt-4 d-grid">
                        <button
                          className="btn btn-success fw-bold btn-block"
                          type="submit"
                        >
                          Login
                        </button>
                      </div>
                      <div className="mt-3 d-grid">
                        <button
                          type="button"
                          className="btn btn-primary fw-bold btn-block"
                          onClick={() => {
                            props.history.push({
                              pathname: "/login-send-otp",
                              state: { mobileNumber: values?.mobileNumber },
                            });
                          }}
                        >
                          Login with OTP
                        </button>
                      </div>
                      {/* <div className="mt-3 text-center">
                        <Link to="/" onClick={()=>props?.history?.push({pathname : "/register-send-otp",state : {mobileNumber : values?.mobileNumber}})} className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          Forgot your password?
                        </Link>
                      </div> */}

                      <div className="mt-4 d-grid">
                        <p className="mb-2">Don&#39;t have an account ? </p>
                        <button
                          type="button"
                          className="btn btn-dark fw-medium btn-block col-12"
                          onClick={() => {
                            props.history.push({
                              pathname: "/register-send-otp",
                              state: { mobileNumber: values?.mobileNumber },
                            });
                          }}
                        >
                          Signup
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                {/* <p>
                  Don&#39;t have an account ?{" "}
                  <button onClick={()=>props?.history?.push({pathname : "/register-send-otp",state : {mobileNumber : values?.mobileNumber}})} color="link" className="btn btn-link p-0 fw-medium">
                    {" "}
                    Signup now{" "}
                  </button>{" "}
                </p> */}
                <p>
                  © {new Date().getFullYear()} Made with{" "}
                  <i className="mdi mdi-heart text-danger" /> by Rainbowingzz
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
