var service = {};
// Local;
if (window.location.href.startsWith("http://localhost")) {
  service.API_URL = "http://localhost:6001/api";
  service.IMG_URL = "http://localhost:6001";
  service.PAYMENT_URL = "http://localhost:6002";
} else {
  service.API_URL = "https://api.numerolution.com/api";
  service.IMG_URL = "https://api.numerolution.com";
  service.PAYMENT_URL = "https://staging.numerolution.com";
}

export default service;
