import React, { useCallback, useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { isEmpty, map } from "lodash";
import * as moment from "moment";
import {
  Button,
  Col,
  Container,
  Row,
  Table,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalBody,
  Card,
  CardBody,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Image
import images from "assets/images";
import companies from "assets/images/companies";
// import BLIV_Partner from "assets/images/logo-small-nobg_new.png";
import new_image from "../../assets/images/english_img.png";
import constant from "../../service/constant";

import * as url from "service/url_helper";
import { ApiServices } from "service/apiServices.js";
import { getSecureToken } from "helpers/utils.js";
import { Constants } from "helpers/constant.js";

const PropertyList = (props) => {
  const { isOpen, toggle } = props;
  //meta title
  document.title = "Orders | Numerolution";
  let userData = getSecureToken(Constants.KEY_USER_DATA);
  const [product, setProject] = useState();
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [orderList, setOrderList] = useState([]);
  const [productModal, setProductModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState({});

  //delete order
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (product) => {
    setProject(product);
    setDeleteModal(true);
  };

  const handleDeleteOrder = () => {
    dispatch(onDeleteProject(product));
    setDeleteModal(false);
  };

  const viewProductList = (order) => {
    setProductModal(true);
    setSelectedOrder(order);
  };

  const hideProductList = (order) => {
    setProductModal(false);
    setSelectedOrder();
  };

  const getOrders = () => {
    try {
      let uri = url.GET_ALL_ORDERS;
      ApiServices.callServiceGet(uri)
        .then((response) => {
          if (response?.result?.type === "success") {
            setOrderList(response.result.data);
          }
        })
        .catch((error) => {+
          console.log("GET_PROPERTIES_BY_PARTNERID API call Error", error);
        });
    } catch (error) {
      console.log("GET_PROPERTIES_BY_PARTNERID API Function Error", error);
    }
  };
  useEffect(() => {
    getOrders();
  }, []);

  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  const convertArrayOfObjectsToCSV = useCallback(() => {
    let newArray = [...orderList].map((item) => {
      let newItem = {
        "#id": item?.paymentOrderId ?? "",
        "Billing Name": item?.billing?.fullName ?? "",
        "Billing Contact": item?.billing?.mobileNumber ?? "",
        "Billing Date": handleValidDate(item.createdAt),
        "Billing Total": `${item?.totalAmount}`,
      };
      return newItem;
    });
    let result;
    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(newArray[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    newArray.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;
        result += item[key];
        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }, [orderList]);

  function downloadCSV() {
    if (orderList?.length <= 0) return toastr.error("No data", "Failed");
    let csv = convertArrayOfObjectsToCSV();
    if (csv == null) return;
    const csvFile = new Blob([csv], { type: "text/csv" });
    const filename = `PDF-ORDERS-${moment(Date.now()).format(
      "DD-MM-YYYY-HH-mm-ss"
    )}.csv`;
    let link = document.createElement("a");
    link.download = filename;
    link.href = window.URL.createObjectURL(csvFile);
    document.body.appendChild(link);
    link.click();
  }

  return (
    <React.Fragment>
      <Modal
        isOpen={productModal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        // tabIndex="-1"
        size="lg"
        toggle={() => hideProductList()}
      >
        <div className="modal-content">
          <ModalHeader>Order Details</ModalHeader>
          <ModalBody>
            <div className="row">
              <h5 className="mb-3">Billing Summary</h5>
              <div className="col-md-6 col-lg-6 col-sm-12">
                <p className="mb-2">
                  Order ID:{" "}
                  <span className="text-primary">
                    {selectedOrder?.paymentOrderId}
                  </span>
                </p>
              </div>
              <div className="col-md-6 col-lg-6 col-sm-12">
                <p className="mb-2">
                  Billing Name:{" "}
                  <span className="text-primary">
                    {selectedOrder?.billing?.fullName}
                  </span>
                </p>
              </div>
              {selectedOrder?.billing?.dob && (
                <div className="col-md-6 col-lg-6 col-sm-12">
                  <p className="mb-2">
                    Date of Birth:{" "}
                    <span className="text-primary">
                      {moment(selectedOrder?.billing?.dob).format("DD-MM-YYYY")}
                    </span>
                  </p>
                </div>
              )}
              <div className="col-md-6 col-lg-6 col-sm-12">
                <p className="mb-2">
                  Billing Number:{" "}
                  <span className="text-primary">
                    {selectedOrder?.billing?.mobileNumber}
                  </span>
                </p>
              </div>
              <div className="col-md-6 col-lg-6 col-sm-12">
                <p className="mb-2">
                  Billing Email:{" "}
                  <span className="text-primary">
                    {selectedOrder?.billing?.email}
                  </span>
                </p>
              </div>
              {selectedOrder?.billing?.city?.length > 0 &&
                selectedOrder?.billing?.state?.length > 0 && (
                  <div className="col-md-6 col-lg-6 col-sm-12">
                    <p className="mb-2">
                      State & City:{" "}
                      <span className="text-primary">
                        {selectedOrder?.billing?.city +
                          ", " +
                          selectedOrder?.billing?.state}
                      </span>
                    </p>
                  </div>
                )}
              {selectedOrder?.billing?.postalCode?.length > 0 && (
                <div className="col-md-6 col-lg-6 col-sm-12">
                  <p className="mb-2">
                    Postal Code:{" "}
                    <span className="text-primary">
                      {selectedOrder?.billing?.postalCode}
                    </span>
                  </p>
                </div>
              )}
              <div className="col-md-6 col-lg-6 col-sm-12">
                <p className="mb-4">
                  Billing Address:{" "}
                  <span className="text-primary">
                    {selectedOrder?.billing?.address}
                  </span>
                </p>
              </div>
            </div>
            <hr />
            <div className="table-responsive">
              <h5>Product Summary</h5>
              <Table className="table align-middle table-nowrap">
                <thead>
                  <tr>
                    <th scope="col">Product</th>
                    <th scope="col">Product Name</th>
                    <th scope="col">Price</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedOrder !== undefined && selectedOrder !== null
                    ? selectedOrder?.order?.map((item, key) => (
                        <tr key={key}>
                          <th scope="row">
                            <div>
                              <img
                                src={
                                  constant.IMG_URL +
                                  item.productId.productImages[0].imagePath
                                }
                                alt=""
                                className="avatar-sm"
                              />
                            </div>
                          </th>
                          <td>
                            <div>
                              <h5 className="text-truncate font-size-14">
                                {item?.productName}
                              </h5>
                              <p className="text-muted mb-0">
                                {item?.price} x {item?.quantity}{" "}
                                {selectedOrder?.currencyCode ?? "INR"}
                              </p>
                            </div>
                          </td>
                          <td>
                            {item?.productTotal}{" "}
                            {selectedOrder?.currencyCode ?? "INR"}
                          </td>
                        </tr>
                      ))
                    : null}
                  {/* <tr>
                                        <td colSpan="2">
                                            <h6 className="m-0 text-end">Sub Total:</h6>
                                        </td>
                                        <td>$ 400</td>
                                    </tr> */}
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Discount:</h6>
                    </td>
                    <td>
                      {selectedOrder?.discountedAmount
                        ? String(selectedOrder.discountedAmount)
                        : "N/A"}{" "}
                      {selectedOrder?.currencyCode ?? "INR"}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Shipping:</h6>
                    </td>
                    <td>
                      {selectedOrder?.shippingCharges
                        ? String(selectedOrder.shippingCharges)
                        : selectedOrder?.billing?.state
                        ? selectedOrder?.billing?.state === "Gujarat"
                          ? "50"
                          : "100"
                        : "0"}{" "}
                      {selectedOrder?.currencyCode ?? "INR"}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Total:</h6>
                    </td>
                    <td>
                      {selectedOrder?.totalAmount}{" "}
                      {selectedOrder?.currencyCode ?? "INR"}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
            {selectedOrder?.affiliateId &&
              !isEmpty(selectedOrder.affiliateId) && (
                <div className="row">
                  <h5 className="mb-3">Affiliate Summary</h5>
                  <div className="col-md-6 col-lg-6 col-sm-12">
                    <p className="mb-2">
                      Affiliate User Name :{" "}
                      <span className="text-primary">
                        {selectedOrder?.affiliateId?.name}
                      </span>
                    </p>
                  </div>
                  <div className="col-md-6 col-lg-6 col-sm-12">
                    <p className="mb-2">
                      Status :{" "}
                      <span
                        className={`${
                          selectedOrder?.affiliateId?.isAffiliate
                            ? "text-success"
                            : "text-danger"
                        } fw-bold`}
                      >
                        {selectedOrder?.affiliateId?.isAffiliate
                          ? "Active"
                          : "Inactive"}
                      </span>
                    </p>
                  </div>
                  <div className="col-md-6 col-lg-6 col-sm-12">
                    <p className="mb-2">
                      Registered Number:{" "}
                      <span className="text-primary">
                        {selectedOrder?.affiliateId?.mobileNumber}
                      </span>
                    </p>
                  </div>
                  <div className="col-md-6 col-lg-6 col-sm-12">
                    <p className="mb-2">
                      Registered Email:{" "}
                      <span className="text-primary">
                        {selectedOrder?.affiliateId?.email}
                      </span>
                    </p>
                  </div>
                  <div className="col-md-6 col-lg-6 col-sm-12">
                    <p className="mb-2">
                      Discount (%) :{" "}
                      <span className="text-primary">
                        {selectedOrder?.affiliateId?.discount}
                      </span>
                    </p>
                  </div>
                  <div className="col-md-6 col-lg-6 col-sm-12">
                    <p className="mb-2">
                      Discount Code:{" "}
                      <span className="text-primary">
                        {selectedOrder?.affiliateId?.discountCode}
                      </span>
                    </p>
                  </div>
                </div>
              )}
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="secondary"
              onClick={() => hideProductList()}
            >
              Close
            </Button>
          </ModalFooter>
        </div>
      </Modal>
      <Card>
        <div className="page-content">
          <Container fluid>
            <Row className="d-flex bg-white p-2 justify-content-between align-items-center w-100 m-0 mb-2">
              <Col className="p-0">
                <h5>Orders</h5>
              </Col>
              <Col className="d-flex justify-content-end align-items-center p-0 d-print-none">
                <Button
                  type="button"
                  color="success"
                  className="btn"
                  onClick={() => window.print()}
                >
                  <i className="fa fa-print me-2" />
                  Print All Data
                </Button>
                <Button
                  type="button"
                  color="success"
                  className="btn ms-2"
                  onClick={() => downloadCSV()}
                >
                  <i className="fas fa-file-csv me-2" />
                  Export CSV
                </Button>
              </Col>
            </Row>

            <Row>
              <Col lg="12">
                <div>
                  <div className="table-responsive">
                    <Table className="product-list-table table-nowrap align-middle table-borderless">
                      <thead>
                        <tr>
                          <th scope="col" style={{ width: "100px" }}>
                            #id
                          </th>
                          <th scope="col">Billing Name</th>
                          <th scope="col">Billing Contact</th>
                          <th scope="col">Billing Date</th>
                          <th scope="col">Billing Total</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {map(orderList, (order, index) => (
                          <tr key={index}>
                            <td>
                              <button
                                className="btn btn-link text-primary p-0"
                                onClick={() => viewProductList(order)}
                              >
                                {order?.paymentOrderId}
                              </button>
                            </td>
                            <td> {order?.billing?.fullName ?? "N/A"}</td>
                            <td> {order?.billing?.mobileNumber ?? "N/A"}</td>
                            <td>
                              {" "}
                              {order?.createdAt
                                ? new Date(order.createdAt).toLocaleDateString(
                                    "en-GB"
                                  )
                                : "N/A"}
                            </td>
                            <td>
                              {" "}
                              {String(order?.totalAmount) +
                                " " +
                                (order?.currencyCode ?? "INR") ?? "N/A"}
                            </td>
                            <td>
                              <i
                                onClick={() => viewProductList(order)}
                                className="mdi mdi-eye text-primary"
                                type="submit"
                              >
                                View
                              </i>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Card>
    </React.Fragment>
  );
};

export default withRouter(PropertyList);
