import React, { useState, useEffect, useRef, useMemo } from "react";
import { Link } from "react-router-dom";

import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardFooter,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  FormFeedback,
} from "reactstrap";
import Select from "react-select";
import Dropzone from "react-dropzone";
import { Formik, useFormik } from "formik";
import { AddProductSchema } from "service/validationSchema";
import JoditEditor from "jodit-react";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { ApiServices } from "service/apiServices";
import * as url from "../../service/url_helper";
import { submit } from "redux-form";
import { getCompressedImages } from "helpers/utils.js";
import { Constants } from "helpers/constant";

const AddProduct = (props) => {
  document.title = "Add Product | Numerolution";
  const [productData, setProductData] = useState({
    productName: "",
    price: "",
    productDescription: "",
    otherDescription: "",
    weight: "",
    dimensions: "",
    modelName: "",
    type: "",
    color: "",
    baseMaterial: "",
    size: "",
  });
  const [roomImgsLimit, setRoomImgsLimit] = useState();
  const [roomImgs, setRoomImgs] = useState([]);
  const [productDesctiption, setProductDescription] = useState({ value: "" });
  const [categories, setCategories] = useState([]);
  const [otherDescription, setOtherDescription] = useState({ value: "" });
  const [benifitsOfProduct, setBenifitsOfProduct] = useState({ value: "" });
  const [legelDesclaimer, setLegelDesclaimer] = useState({ value: "" });
  const [howToWear, sethowToWear] = useState({ value: "" });
  const [safetyInfo, setSafetyInfo] = useState({ value: "" });
  const [price, setPrice] = useState({
    INR: "0",
    AUD: "0",
    CAD: "0",
    USD: "0",
    GBP: "0",
    EUR: "0",
  });
  const currencyCodes = ["INR", "AUD", "CAD", "USD", "GBP", "EUR"];
  const editor = useRef(null);
  const handleContent = (e, eventName) => {
    if (eventName === "productDescription") {
      productDesctiption.value = e;
    } else if (eventName === "otherDescription") {
      otherDescription.value = e;
    } else if (eventName === "benifitsOfProduct") {
      benifitsOfProduct.value = e;
    } else if (eventName === "howToWear") {
      howToWear.value = e;
    } else if (eventName === "legelDesclaimer") {
      legelDesclaimer.value = e;
    } else if (eventName === "safetyInfo") {
      safetyInfo.value = e;
    }
  };

  const handleChangePrice = (e, key) => {
    setPrice({ ...price, [key]: e.target.value });
  };

  const handleAcceptedFiles = async (files) => {
    await getCompressedImages(files)
      .then((compressedImgs) => {
        let newFiles = compressedImgs.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            formattedSize: formatBytes(file.size),
          })
        );
        if (newFiles.length === compressedImgs.length) {
          if (
            roomImgsLimit > 0 &&
            [...roomImgs, ...newFiles].length <= roomImgsLimit
          )
            return setRoomImgs([...roomImgs, ...newFiles]);
          toastr.error("Limit exceeded", "Error");
        }
      })
      .catch((error) => {
        console.log("error =>", error);
        toastr.error(error ?? "Error Occred while uploading images", "Error");
      });
  };

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const getAllCategory = () => {
    try {
      let uri = url.GET_ALL_CATEGORY;
      ApiServices.callServiceGet(uri)
        .then((response) => {
          if (response?.result?.type === Constants.SUCCESS) {
            setCategories(response.result.data);
          }
        })
        .catch((error) => console.log("catch getAllCategory", error));
    } catch (error) {
      console.log("catch getAllCategory", error);
    }
  };

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      productName: productData.productName
        ? String(productData.productName)
        : "",
      // productDescription: productData.productDescription
      //   ? String(productData.productDescription)
      //   : "",
      // otherDescription: productData.otherDescription
      //   ? String(productData.otherDescription)
      //   : "",
      weight: productData.weight ? String(productData.weight) : "",
      dimensions: productData.dimensions ? String(productData.dimensions) : "",
      modelName: productData.modelName ? String(productData.modelName) : "",
      type: productData.type ? String(productData.type) : "",
      color: productData.color ? String(productData.color) : "",
      baseMaterial: productData.baseMaterial ? productData.baseMaterial : "",
      size: productData.size ? String(productData.size) : "",
      category: { categoryName: "" },
    },
    validationSchema: AddProductSchema,
    onSubmit: (values) => {
      submitProduct(values);
    },
  });

  const {
    values,
    touched,
    errors,
    handleChange,
    setFieldTouched,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = validation;

  const submitProduct = (values) => {
    try {
      let reqObj = {
        categoryId: values.category._id,
        productName: values.productName,
        price: price,
        productDescription: productDesctiption.value,
        otherDescription: otherDescription.value,
        benifitsOfProduct: benifitsOfProduct.value,
        legelDesclaimer: legelDesclaimer.value,
        howToWear: howToWear.value,
        safetyInfo: safetyInfo.value,
        specification: {
          weight: values.weight,
          dimensions: values.dimensions,
          modelName: values.modelName,
          type: values.type,
          color: values.color,
          baseMaterial: values.baseMaterial,
          size: values.size,
        },
      };
      let uri = url.ADD_PRODUCT;
      const formData = new FormData();
      formData.append("data", JSON.stringify(reqObj));
      roomImgs.map((img) => {
        formData.append("productImages", img);
      });
      ApiServices.callServicePostWithFormData(uri, formData).then(
        (response) => {
          setTimeout(() => {
            props.history.push("/product/list");
          }, 1000);
        }
      );
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    setRoomImgsLimit(roomImgs?.length > 0 ? 10 - roomImgs.length : 10);
  }, [validation]);

  useEffect(() => {
    getAllCategory();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Product" breadcrumbItem="Add Product" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <CardTitle>Basic Information</CardTitle>
                  <CardSubtitle className="mb-4">
                    Fill all information below
                  </CardSubtitle>
                  <Form>
                    <Row className="mb-4">
                      <Label
                        htmlFor="billing-address"
                        md="2"
                        className="col-form-label"
                      >
                        Category
                        <span style={{ color: "red" }}> *</span>
                      </Label>
                      <Col md="10">
                        <Select
                          styles={{
                            menu: (provided) => ({
                              ...provided,
                              zIndex: 100,
                            }),
                          }}
                          name="category"
                          id="category"
                          placeholder="Select category"
                          value={values.category}
                          onChange={(e) => {
                            if (e) {
                              setFieldValue("category", e);
                              return;
                            }
                            setFieldValue("category", { categoryName: "" });
                          }}
                          options={categories}
                          getOptionLabel={(e) => e.categoryName}
                          getOptionValue={(e) => e.categoryName}
                          onBlur={() => {
                            setFieldTouched("category", true);
                          }}
                          classNamePrefix="select2-selection"
                          closeMenuOnSelect={true}
                        />
                        {touched.category && errors.category ? (
                          <p className="small text-danger mb-0">
                            {errors?.category?.categoryName}
                          </p>
                        ) : null}
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="productName">Product Name</Label>
                          <Input
                            id="productName"
                            name="productName"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Enter product name"
                            className="form-control"
                            invalid={
                              touched.productName && errors.productName
                                ? true
                                : false
                            }
                          />
                          {touched.productName && errors.productName ? (
                            <FormFeedback type="invalid">
                              {errors.productName}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col sm="6">
                        <Row>
                          {currencyCodes.map((currency, index) => {
                            return (
                              <Col key={currency + index}>
                                <div className="mb-3">
                                  <Label htmlFor={`${currency}`}>
                                    {currency}
                                  </Label>
                                  <Input
                                    id={`${currency}`}
                                    name="price"
                                    type="text"
                                    onChange={(e) =>
                                      handleChangePrice(e, currency)
                                    }
                                    //onBlur={handleBlur}
                                    defaultValue={price[currency] || ""}
                                    className="form-control"
                                    placeholder="Enter product price"
                                  />
                                </div>
                              </Col>
                            );
                          })}
                        </Row>
                      </Col>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="productdesc">
                            Product Description
                          </Label>
                          <JoditEditor
                            ref={editor}
                            name="productDescription"
                            id="productDescription"
                            value={productDesctiption.value}
                            onChange={(e) =>
                              handleContent(e, "productDescription")
                            }
                          />
                        </div>
                      </Col>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="productdesc">Other Description</Label>
                          <JoditEditor
                            ref={editor}
                            name="otherDescription"
                            id="otherDescription"
                            value={otherDescription.value}
                            onChange={(e) =>
                              handleContent(e, "otherDescription")
                            }
                          />
                          {touched.otherDescription &&
                          errors.otherDescription ? (
                            <FormFeedback type="invalid">
                              {errors.otherDescription}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="benifitsOfProduct">
                            Benifits Of Product
                          </Label>
                          <JoditEditor
                            ref={editor}
                            name="benifitsOfProduct"
                            id="benifitsOfProduct"
                            value={benifitsOfProduct.value}
                            onChange={(e) =>
                              handleContent(e, "benifitsOfProduct")
                            }
                          />
                        </div>
                      </Col>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="howToWear">How To Wear</Label>
                          <JoditEditor
                            ref={editor}
                            name="howToWear"
                            id="howToWear"
                            value={howToWear.value}
                            onChange={(e) => handleContent(e, "howToWear")}
                          />
                        </div>
                      </Col>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="howToWear">Legel Desclaimer</Label>
                          <JoditEditor
                            ref={editor}
                            name="legelDesclaimer"
                            id="legelDesclaimer"
                            value={legelDesclaimer.value}
                            onChange={(e) =>
                              handleContent(e, "legelDesclaimer")
                            }
                          />
                        </div>
                      </Col>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="safetyInfo">Safety Info</Label>
                          <JoditEditor
                            ref={editor}
                            name="safetyInfo"
                            id="safetyInfo"
                            value={safetyInfo.value}
                            onChange={(e) => handleContent(e, "safetyInfo")}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <CardTitle>Specifications</CardTitle>
                  <CardSubtitle className="mb-3">
                    Fill all information below
                  </CardSubtitle>
                  <Form>
                    <Row>
                      <Col sm={6}>
                        <div className="mb-3">
                          <Label htmlFor="weight">Weight</Label>
                          <Input
                            onChange={handleChange}
                            onBlur={handleBlur}
                            id="weight"
                            name="weight"
                            placeholder="Enter product weight"
                            type="text"
                            className="form-control"
                            invalid={
                              touched.weight && errors.weight ? true : false
                            }
                          />
                          {touched.weight && errors.weight ? (
                            <FormFeedback type="invalid">
                              {errors.weight}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col sm={6}>
                        <div className="mb-3">
                          <Label htmlFor="dimensions">Dimensions</Label>
                          <Input
                            onChange={handleChange}
                            onBlur={handleBlur}
                            id="dimensions"
                            name="dimensions"
                            placeholder="Enter product dimensions"
                            type="text"
                            className="form-control"
                            invalid={
                              touched.dimensions && errors.dimensions
                                ? true
                                : false
                            }
                          />
                          {touched.dimensions && errors.dimensions ? (
                            <FormFeedback type="invalid">
                              {errors.dimensions}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col sm={6}>
                        <div className="mb-3">
                          <Label htmlFor="modelName">Model Name</Label>
                          <Input
                            onChange={handleChange}
                            onBlur={handleBlur}
                            id="modelName"
                            name="modelName"
                            type="text"
                            placeholder="Enter model name"
                            className="form-control"
                            invalid={
                              touched.modelName && errors.modelName
                                ? true
                                : false
                            }
                          />
                          {touched.modelName && errors.modelName ? (
                            <FormFeedback type="invalid">
                              {errors.modelName}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col sm={6}>
                        <div className="mb-3">
                          <Label htmlFor="type">Type</Label>
                          <Input
                            onChange={handleChange}
                            onBlur={handleBlur}
                            id="type"
                            name="type"
                            type="text"
                            placeholder="Enter product type"
                            className="form-control"
                            invalid={touched.type && errors.type ? true : false}
                          />
                          {touched.type && errors.type ? (
                            <FormFeedback type="invalid">
                              {errors.type}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col sm={6}>
                        <div className="mb-3">
                          <Label htmlFor="color">Color</Label>
                          <Input
                            onChange={handleChange}
                            onBlur={handleBlur}
                            id="color"
                            name="color"
                            placeholder="Enter product color"
                            type="text"
                            className="form-control"
                            invalid={
                              touched.color && errors.color ? true : false
                            }
                          />
                          {touched.color && errors.color ? (
                            <FormFeedback type="invalid">
                              {errors.color}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col sm={6}>
                        <div className="mb-3">
                          <Label htmlFor="baseMaterial">Base Material</Label>
                          <Input
                            onChange={handleChange}
                            onBlur={handleBlur}
                            id="baseMaterial"
                            name="baseMaterial"
                            type="text"
                            placeholder="Enter product base material"
                            className="form-control"
                            invalid={
                              touched.baseMaterial && errors.baseMaterial
                                ? true
                                : false
                            }
                          />
                          {touched.baseMaterial && errors.baseMaterial ? (
                            <FormFeedback type="invalid">
                              {errors.baseMaterial}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col sm={6}>
                        <div className="mb-3">
                          <Label htmlFor="size">Size</Label>
                          <Input
                            onChange={handleChange}
                            onBlur={handleBlur}
                            id="size"
                            name="size"
                            placeholder="Enter product size"
                            type="text"
                            className="form-control"
                            invalid={touched.size && errors.size ? true : false}
                          />
                          {touched.size && errors.size ? (
                            <FormFeedback type="invalid">
                              {errors.size}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <CardTitle className="mb-3">Product Images</CardTitle>
                  <Form>
                    <Dropzone
                      onDrop={(acceptedFiles) => {
                        handleAcceptedFiles(acceptedFiles);
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone">
                          <div
                            className="dz-message needsclick"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <div className="dz-message needsclick">
                              <div className="mb-3">
                                <i className="display-4 text-muted bx bxs-cloud-upload" />
                              </div>
                              <h4>Drop files here or click to upload.</h4>
                            </div>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    <div className="dropzone-previews mt-3" id="file-previews">
                      {roomImgs.map((f, i) => {
                        return (
                          <Card
                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                            key={i + "-file"}
                          >
                            <div className="p-2">
                              <Row className="align-items-center">
                                <Col className="col-auto">
                                  <img
                                    data-dz-thumbnail=""
                                    height="80"
                                    className="avatar-sm rounded bg-light"
                                    alt={f.name}
                                    src={f.preview}
                                  />
                                </Col>
                                <Col>
                                  <Link
                                    to="#"
                                    className="text-muted font-weight-bold"
                                  >
                                    {f.name}
                                  </Link>
                                  <p className="mb-0">
                                    <strong>{f.formattedSize}</strong>
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        );
                      })}
                    </div>
                  </Form>
                  <hr />
                  <div
                    style={{ justifyContent: "center" }}
                    className="d-flex flex-wrap gap-2"
                  >
                    <Button
                      color="primary"
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Save Changes
                    </Button>
                    <Button type="submit" color="secondary">
                      Cancel
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AddProduct;
