import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
import PagesComingsoon from "pages/Utility/pages-comingsoon";
import OnePager from "pages/Home/CryptoIcoLanding/index";

//Products
import AddEditProducts from "pages/Products/AddProducts";
import ProductList from "pages/Products/ProductList";
import ProductDetails from "pages/Products/ProductDetails";
import EditProduct from "pages/Products/EditProduct";
import OrderList from "pages/Order/Order";

//pdf
import GetPdf from "pages/Pdf/GetPdf";
import ReadyPdf from "pages/Pdf/ReadyPdf";
import PDFUserList from "pages/Pdf/PDFUserList";
import PrivacyPolicy from "pages/PrivacyPolicy/PrivacyPolicy";
import AddEditAffiliate from "pages/Affiliate/AddEditAffiliate";
import AffiliateUserList from "pages/Affiliate/AffiliateUserList";
import { ROLES } from "helpers/constant";

const authProtectedRoutes = [
  {
    path: "/dashboard",
    exact: true,
    component: Dashboard,
    allowedFor: [ROLES.admin],
  },
  //profile

  //Orderone
  {
    path: "/order",
    exact: true,
    component: OrderList,
    allowedFor: [ROLES.admin, ROLES.affiliate],
  },

  //Products
  {
    path: "/product/add",
    exact: true,
    component: AddEditProducts,
    allowedFor: [ROLES.admin],
  },
  {
    path: "/product/list",
    exact: true,
    component: ProductList,
    allowedFor: [ROLES.admin],
  },
  {
    path: "/product/detail/:id",
    exact: true,
    component: ProductDetails,
    allowedFor: [ROLES.admin],
  },
  {
    path: "/product/edit/:id",
    exact: true,
    component: EditProduct,
    allowedFor: [ROLES.admin],
  },
  {
    path: "/pdf/users",
    exact: true,
    component: PDFUserList,
    allowedFor: [ROLES.admin],
  },
  {
    path: "/privacy-policy",
    exact: true,
    component: PrivacyPolicy,
    allowedFor: [ROLES.admin],
  },
  {
    path: "/affiliate/add",
    exact: true,
    component: AddEditAffiliate,
    allowedFor: [ROLES.admin],
  },
  {
    path: "/affiliate/edit/:id",
    exact: true,
    component: AddEditAffiliate,
    allowedFor: [ROLES.admin],
  },
  {
    path: "/affiliate",
    exact: true,
    component: AffiliateUserList,
    allowedFor: [ROLES.admin],
  },

  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/dashboard" />,
    allowedFor: [ROLES.admin],
  },
];

const publicRoutes = [
  { path: "/login", exact: true, component: Login },
  { path: "/logout", exact: true, component: Logout },
  // { path: "/product/add", exact: true, component: AddEditProducts },
  // { path: "/product/list", exact: true, component: ProductList },
  // { path: "/product/detail/:id", exact: true, component: ProductDetails },
  { path: "/", exact: true, component: () => <Redirect to="/login" /> },
];

export { authProtectedRoutes, publicRoutes };
