import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Row, Col, Card } from "react-bootstrap";
import {
  Container,
  CardBody,
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  FormGroup,
  Label,
  FormFeedback,
  Form,
} from "reactstrap";
import service from "service/constant";
import { Link } from "react-router-dom";
import toastr from "toastr";

import classNames from "classnames";

//import Charts
import StackedColumnChart from "./StackedColumnChart";

//import action
import { getChartsData as onGetChartsData } from "../../store/actions";

import modalimage1 from "../../assets/images/product/img-7.png";
import modalimage2 from "../../assets/images/product/img-4.png";

// Pages Components
import WelcomeComp from "./WelcomeComp";
import MonthlyEarning from "./MonthlyEarning";
import SocialSource from "./SocialSource";
import ActivityComp from "./ActivityComp";
import TopCities from "./TopCities";
import LatestTranaction from "./LatestTranaction";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";

import { useFormik } from "formik";
import * as Yup from "yup";
// import { setItem, getItem } from "service/utils";
import { getSecureToken, setSecureToken } from "helpers/utils.js";
import { Constants } from "helpers/constant.js";
import { ApiServices } from "service/apiServices";
import * as url from "service/url_helper";

//redux
import { useSelector, useDispatch } from "react-redux";
import { values } from "lodash";
import moment from "moment";

const Dashboard = (props) => {
  toastr.options = {
    positionClass: "toast-top-right",
    timeOut: 2000,
    extendedTimeOut: 1000,
    closeButton: true,
    progressBar: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: "swing",
    hideEasing: "swing",
    showMethod: "fadeIn",
    hideMethod: "fadeOut",
    showDuration: 300,
    hideDuration: 500,
  };
  const [modal, setmodal] = useState(false);
  const [subscribemodal, setSubscribemodal] = useState(false);
  const [numData, setNumData] = useState({});

  const { chartsData } = useSelector((state) => ({
    chartsData: state.Dashboard.chartsData,
  }));

  const reports = [
    { title: "Orders", iconClass: "bx-copy-alt", description: "1,235" },
    { title: "Revenue", iconClass: "bx-archive-in", description: "$35, 723" },
    {
      title: "Average Price",
      iconClass: "bx-purchase-tag-alt",
      description: "$16.2",
    },
  ];
  const languages = ["Gujarati", "English", "Hindi"];
  const [pdfDownload, setPdfDownload] = useState();
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: "admin",
      contact: "9876543210",
      dob: "",
      language: "",
      email: "solution@numerolution.com",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Your Name"),
      contact: Yup.string().required("Please Enter Your Phone Number"),
      dob: Yup.string().required("Please Select Your Birthdate"),
      language: Yup.string().required("Language is required"),
      email: Yup.string()
        .required("Please enter your email address")
        .email("Not a valid email address"),
    }),
    onSubmit: (values) => {
      let uri = url.CREATE_PDF_ADMIN;
      ApiServices.callServicePostWithBodyData(uri, values).then((response) => {
        if (response.result.type === "success") {
          let FinalPDFPath = service.IMG_URL + "/" + response.result.pdfPath;
          setPdfDownload(FinalPDFPath);
          //Automatic PDF Download
          onButtonClick(FinalPDFPath);
          setButtonDisabled(false);
        }
      });
    },
  });

  const getNumerologyData = async () => {
    try {
      if (
        validation.values.dob.length === 0 &&
        validation.values.language.length === 0
      ) {
        await validation.setFieldTouched("dob", true);
        await validation.setFieldTouched("language", true);
        await validation.validateField("dob");
        await validation.validateField("language");
        return;
      }
      let uri = url.GET_NUM_DATA;
      let reqObj = {
        dob: validation.values.dob,
        language: validation.values.language,
      };
      ApiServices.callServicePostWithBodyData(uri, reqObj)
        .then((response) => {
          if (response.result.type === Constants.SUCCESS) {
            setNumData(response.result.data);
            return;
          }
          toastr.error("Unable to fetch data", "Error");
        })
        .catch((error) => {
          toastr.error("Unable to fetch data", "Error");
          console.log("catch GET_NUM_DATA error", error);
        });
    } catch (error) {
      console.log("catch GET_NUM_DATA error", error);
    }
  };

  const onButtonClick = (pdfDownload) => {
    // using Java Script method to get PDF file
    fetch(pdfDownload).then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "Numerology-Report.pdf";
        alink.click();
      });
    });
  };

  useEffect(() => {
    setTimeout(() => {
      //setSubscribemodal(true);
    }, 2000);
  }, []);

  const [periodData, setPeriodData] = useState([]);
  const [periodType, setPeriodType] = useState("yearly");

  useEffect(() => {
    setPeriodData(chartsData);
  }, [chartsData]);

  const onChangeChartPeriod = (pType) => {
    setPeriodType(pType);
    dispatch(onGetChartsData(pType));
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(onGetChartsData("yearly"));
  }, [dispatch]);

  //meta title
  document.title = "Dashboard | Numerolution";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Dashboard")}
          />
          <Row className="px-4">
            <Col lg="4" className="my-2">
              <Card
                className="mb-0 mt-5 mt-lg-0"
                style={{
                  boxShadow: "0px 0px 10px 2px #62ffca",
                  opacity: "0.95",
                  borderRadius: "10px",
                }}
              >
                <CardBody>
                  <div className="mt-4">
                    <Form
                      className="needs-validation"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <Row>
                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="validationCustom01">
                              Name <sup className="text-danger">*</sup>
                            </Label>
                            <Input
                              name="name"
                              placeholder="Name"
                              type="text"
                              className="form-control"
                              id="validationCustom01"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.name || ""}
                              invalid={
                                validation.touched.name &&
                                validation.errors.name
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.name &&
                            validation.errors.name ? (
                              <FormFeedback type="invalid">
                                {validation.errors.name}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="validationCustom02">
                              Mobile number <sup className="text-danger">*</sup>{" "}
                            </Label>
                            <Input
                              name="contact"
                              placeholder="Mobile number"
                              type="number"
                              className="form-control"
                              id="validationCustom02"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.contact || ""}
                              invalid={
                                validation.touched.contact &&
                                validation.errors.contact
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.contact &&
                            validation.errors.contact ? (
                              <FormFeedback type="invalid">
                                {validation.errors.contact}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="validationCustom03">
                              Birth Date <sup className="text-danger">*</sup>
                            </Label>
                            <Input
                              name="dob"
                              placeholder="Your birthdate"
                              type="date"
                              className="form-control"
                              id="validationCustom03"
                              min={Date.now()}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.dob || ""}
                              invalid={
                                validation.touched.dob && validation.errors.dob
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.language &&
                            validation.errors.dob ? (
                              <FormFeedback type="invalid">
                                {validation.errors.dob}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="validationCustom04">
                              Language <sup className="text-danger">*</sup>
                            </Label>
                            <Input
                              name="language"
                              placeholder="Choose language"
                              type="select"
                              className="form-control"
                              id="validationCustom04"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.language || ""}
                              invalid={
                                validation.touched.language &&
                                validation.errors.language
                                  ? true
                                  : false
                              }
                            >
                              <option value="" disabled hidden>
                                Choose Language
                              </option>
                              {languages?.map((item, key) => (
                                <option key={key}>{item}</option>
                              ))}
                            </Input>
                            {validation.touched.language &&
                            validation.errors.language ? (
                              <FormFeedback type="invalid">
                                {validation.errors.language}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col md="12">
                          <FormGroup className="mb-3">
                            <Label htmlFor="validationCustom04">
                              Email <sup className="text-danger">*</sup>
                            </Label>
                            <Input
                              name="email"
                              placeholder="Email"
                              type="email"
                              className="form-control"
                              id="validationCustom04"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                validation.touched.email &&
                                validation.errors.email
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.email &&
                            validation.errors.email ? (
                              <FormFeedback type="invalid">
                                {validation.errors.email}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>
                      </Row>
                      <div className="text-center mt-4">
                        <Button color="success" type="submit">
                          Get Results Now
                        </Button>
                        <Button
                          color="primary"
                          className="ms-2"
                          onClick={(e) => {
                            e.preventDefault();
                            getNumerologyData();
                          }}
                        >
                          Show Data
                        </Button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="8" className="my-2">
              <Row>
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <Card>
                    <Card.Body className="d-flex align-items-center justify-content-between">
                      <h4 className="mb-0 fw-bolder">Numerology Data</h4>
                    </Card.Body>
                    <Card.Body>
                      <Row className="form-group">
                        {Object.keys(numData).length > 0 ? (
                          <div>
                            <div className="my-2 d-flex flex-row">
                              <h5 className="mb-0">Date of Birth: </h5>
                              <p className="mb-0 ms-2 fw-bolder">
                                {moment(new Date(numData?.dob)).format(
                                  "DD-MM-YYYY"
                                )}
                              </p>
                            </div>
                            <div className="my-2 d-flex flex-row">
                              <h5 className="mb-0">Positive Numbers : </h5>
                              <p className="mb-0 ms-2 fw-bolder">
                                {numData?.positiveNumbers?.toString()}
                              </p>
                            </div>
                            <div className="my-2 d-flex flex-row align-items-center">
                              <h5 className="mb-0">Negative Numbers : </h5>
                              <h6 className="mb-0 ms-2 fw-bolder">
                                {numData?.negativeNumbers?.toString()}
                              </h6>
                            </div>
                            <h5 className="my-2">Plane Data : </h5>
                            {numData?.planeData?.length > 0 ? (
                              <ul>
                                {numData.planeData.map((plane, index) => (
                                  <li
                                    key={index + plane.planeNumber}
                                  >{`${plane.planeNumber} : ${plane.planeResult}`}</li>
                                ))}
                              </ul>
                            ) : (
                              <p className="text-muted text-center">
                                No planes available
                              </p>
                            )}
                          </div>
                        ) : (
                          <p className="text-muted text-center">
                            No data
                            <br />
                            Please select <strong>dob</strong> and{" "}
                            <strong>language</strong> to get results
                          </p>
                        )}
                      </Row>
                    </Card.Body>
                  </Card>
                </div>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xl="12">
              {/* <WelcomeComp /> */}
              {/* <MonthlyEarning /> */}
            </Col>
            <Col xl="8">
              {/* <Row>
                {reports.map((report, key) => (
                  <Col md="4" key={"_col_" + key}>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              {"Ghar"}
                            </p>
                            <h4 className="mb-0">{"0"}</h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className={
                                  "bx " + report.iconClass + " font-size-24"
                                }
                              ></i>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row> */}

              {/* <Card>
                <CardBody>
                  <div className="d-sm-flex flex-wrap">
                    <h4 className="card-title mb-4">Email Sent</h4>
                    <div className="ms-auto">
                      <ul className="nav nav-pills">
                        <li className="nav-item">
                          <Link
                            to="#"
                            className={classNames(
                              { active: periodType === "weekly" },
                              "nav-link"
                            )}
                            onClick={() => {
                              onChangeChartPeriod("weekly");
                            }}
                            id="one_month"
                          >
                            Week
                          </Link>{" "}
                        </li>
                        <li className="nav-item">
                          <Link
                            to="#"
                            className={classNames(
                              { active: periodType === "monthly" },
                              "nav-link"
                            )}
                            onClick={() => {
                              onChangeChartPeriod("monthly");
                            }}
                            id="one_month"
                          >
                            Month
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="#"
                            className={classNames(
                              { active: periodType === "yearly" },
                              "nav-link"
                            )}
                            onClick={() => {
                              onChangeChartPeriod("yearly");
                            }}
                            id="one_month"
                          >
                            Year
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <StackedColumnChart periodData={periodData} />
                </CardBody>
              </Card> */}
            </Col>
          </Row>

          {/* <Row>
            <Col xl="4">
              <SocialSource />
            </Col>
            <Col xl="4">
              <ActivityComp />
            </Col>

            <Col xl="4">
              <TopCities />
            </Col>
          </Row> */}
          {/* 
          <Row>
            <Col lg="12">
              <LatestTranaction />
            </Col>
          </Row> */}
        </Container>
      </div>

      {/* subscribe ModalHeader */}
      {/* <Modal
        isOpen={subscribemodal}
        role="dialog"
        autoFocus={true}
        centered
        data-toggle="modal"
        toggle={() => {
          setSubscribemodal(!subscribemodal);
        }}
      >
        <div>
          <ModalHeader
            className="border-bottom-0"
            toggle={() => {
              setSubscribemodal(!subscribemodal);
            }}
          ></ModalHeader>
        </div>
        <div className="modal-body">
          <div className="text-center mb-4">
            <div className="avatar-md mx-auto mb-4">
              <div className="avatar-title bg-light  rounded-circle text-primary h1">
                <i className="mdi mdi-email-open"></i>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-xl-10">
                <h4 className="text-primary">Subscribe !</h4>
                <p className="text-muted font-size-14 mb-4">
                  Subscribe our newletter and get notification to stay update.
                </p>

                <div
                  className="input-group rounded bg-light"
                >
                  <Input
                    type="email"
                    className="form-control bg-transparent border-0"
                    placeholder="Enter Email address"
                  />
                  <Button color="primary" type="button" id="button-addon2">
                    <i className="bx bxs-paper-plane"></i>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal> */}

      {/* <Modal
        isOpen={modal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          setmodal(!modal);
        }}
      >
        <div>
          <ModalHeader
            toggle={() => {
              setmodal(!modal);
            }}
          >
            Order Details
          </ModalHeader>
          <ModalBody>
            <p className="mb-2">
              Product id: <span className="text-primary">#SK2540</span>
            </p>
            <p className="mb-4">
              Billing Name: <span className="text-primary">Neal Matthews</span>
            </p>

            <div className="table-responsive">
              <Table className="table table-centered table-nowrap">
                <thead>
                  <tr>
                    <th scope="col">Product</th>
                    <th scope="col">Product Name</th>
                    <th scope="col">Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">
                      <div>
                        <img src={modalimage1} alt="" className="avatar-sm" />
                      </div>
                    </th>
                    <td>
                      <div>
                        <h5 className="text-truncate font-size-14">
                          Wireless Headphone (Black)
                        </h5>
                        <p className="text-muted mb-0">$ 225 x 1</p>
                      </div>
                    </td>
                    <td>$ 255</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <div>
                        <img src={modalimage2} alt="" className="avatar-sm" />
                      </div>
                    </th>
                    <td>
                      <div>
                        <h5 className="text-truncate font-size-14">
                          Hoodie (Blue)
                        </h5>
                        <p className="text-muted mb-0">$ 145 x 1</p>
                      </div>
                    </td>
                    <td>$ 145</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Sub Total:</h6>
                    </td>
                    <td>$ 400</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Shipping:</h6>
                    </td>
                    <td>Free</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Total:</h6>
                    </td>
                    <td>$ 400</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="secondary"
              onClick={() => {
                setmodal(!modal);
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </div>
      </Modal> */}
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(Dashboard);
