import React, { useEffect, useState } from "react";
import { Row, Col, Card, Modal, Button } from "react-bootstrap";
import {
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Form,
  CardBody,
  CardTitle,
  CardSubtitle,
  Label,
  Badge,
} from "reactstrap";
import Select from "react-select";
import { Link } from "react-router-dom";

import classnames from "classnames";
import * as url from "service/url_helper";
import { ApiServices } from "service/apiServices.js";
import { Constants } from "helpers/constant.js";
import { options } from "../../helpers/ReactSelectOptions";
import Dropzone from "react-dropzone";
import { getCompressedImages } from "helpers/utils.js";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import constant from "../../service/constant";

//import sweet alert
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

export const PropertyDetails = (props) => {
  toastr.options = {
    positionClass: "toast-top-right",
    timeOut: 1200,
    extendedTimeOut: 1000,
    closeButton: true,
    progressBar: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: "swing",
    hideEasing: "swing",
    showMethod: "fadeIn",
    hideMethod: "fadeOut",
    showDuration: 300,
    hideDuration: 500,
  };
  document.title = "Product Details | Numerolution";
  const [productDetails, setProductDetails] = useState({});
  const [propertyDetails2, setPropertyDetails2] = useState({});
  const [deleteModal, setDeleteModal] = useState({ value: false });
  const [customActiveTab, setcustomActiveTab] = useState("1");
  const [imgType, setImgType] = useState();
  const [propertyFront, setPropertyFront] = useState([]);
  const [propertyBack, setPropertyBack] = useState([]);
  const [corridorImgs, setCorridorImgs] = useState([]);
  const [roomImgs, setRoomImgs] = useState([]);
  const [moreImgs, setMoreImgs] = useState([]);
  const [roomImgsLimit, setRoomImgsLimit] = useState();

  const getProductById = () => {
    let uri = url.GET_PRODUCT_BY_ID + props.match.params.id;
    ApiServices.callServiceGet(uri)
      .then((response) => {
        if (response?.result) {
          setProductDetails(response.result.data);
          setRoomImgsLimit(
            response.result.data.productImages.length > 0
              ? 10 - response.result.data.productImages.length
              : 10
          );
        }
      })
      .catch((error) => console.log("error", error));
  };

  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  const deleteImage = (item) => {
    let uri = url.DELETE_PRODUCT_IMAGE;
    let reqObj = {
      imageId: item,
      productId: props.match.params.id,
    };
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      title: `Delete Image`,
      text: `Are you sure you want to delete this image ?`,
      type: "warning",
      showCancelButton: true,
      showCloseButton: true,
    }).then((res) => {
      if (res && res.value) {
        ApiServices.callServicePostWithBodyData(uri, reqObj)
          .then((response) => {
            if (response.result.type == "error") {
              MySwal.fire("", response.result.message, "warning");
            } else {
              MySwal.fire(
                "",
                `Deleted  successfully!`,
                response.result.type == "success" ? "success" : "warning"
              );
              getProductById();
            }
          })
          .catch((err) => console.log("error", err));
      } else {
        MySwal.fire("", "Cancelled", "error");
      }
      return;
    });
  };

  const handleAcceptedFiles = async (files) => {
    await getCompressedImages(files)
      .then((compressedImgs) => {
        let newFiles = compressedImgs.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            formattedSize: formatBytes(file.size),
          })
        );
        if (newFiles.length === compressedImgs.length) {
          if (
            roomImgsLimit > 0 &&
            [...roomImgs, ...newFiles].length <= roomImgsLimit
          )
            return setRoomImgs([...roomImgs, ...newFiles]);
          toastr.error("Limit exceeded", "Error");
        }
      })
      .catch((error) => {
        console.log("error =>", error);
        toastr.error(error ?? "Error Occred while uploading images", "Error");
      });
  };

  const updateImages = () => {
    const MySwal = withReactContent(Swal);
    if (roomImgs?.length === 0)
      return MySwal.fire(
        "",
        "No images to be uploaded, please select at least one",
        "warning"
      );
    try {
      let reqObj = {
        productId: props.match.params.id,
      };
      const formData = new FormData();
      formData.append("data", JSON.stringify(reqObj));
      roomImgs.map((img) => {
        formData.append("productImages", img);
      });

      let uri = url.UPDATE_PRODUCT + props.match.params.id;
      ApiServices.callServicePutWithFormData(uri, formData)
        .then((response) => {
          if (response?.result) {
            MySwal.fire("Product Images Updated successfully", "success").then(
              (res) => {
                if (res.value) {
                  getProductById();
                  setRoomImgs([]);
                }
              }
            );
          } else {
          }
        })
        .catch((error) => console.log("UPDATE_PRODUCT API Call Error", error));
    } catch (error) {
      console.log("UPDATE_PRODUCT API Function Error", error);
    }
  };

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const deleteProduct = () => {
    try {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            let uri = url.GET_PRODUCT_BY_ID + props.match.params.id;
            ApiServices.callServiceDelete(uri)
              .then((response) => {
                if (response?.result) {
                  setProductDetails(response.result.data);
                  swalWithBootstrapButtons.fire(
                    "Deleted!",
                    "Your product has been deleted.",
                    "success"
                  );
                  setTimeout(() => {
                    props?.history?.push("/product/list");
                  }, 2000);
                }
              })
              .catch((error) => console.log("error", error));
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              "Cancelled",
              "Your product file is safe :)",
              "error"
            );
          }
        });
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    getProductById();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Nav tabs className="nav-tabs-custom nav-justified prop-tab-width ">
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: customActiveTab === "1",
                })}
                onClick={() => {
                  toggleCustom("1");
                }}
              >
                <span className="d-sm-block">Details</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: customActiveTab === "2",
                })}
                onClick={() => {
                  toggleCustom("2");
                }}
              >
                <span className=" d-sm-block">Images</span>
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={customActiveTab} className=" my-3">
            <TabPane tabId="1">
              <Row>
                <Col sm={12} className="tab-user-card">
                  <Row className="mb-n4">
                    <Col>
                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                          <Card>
                            <Card.Body className="d-flex align-items-center justify-content-start">
                              <h3 className="mb-0 text-primary fw-bold">
                                {productDetails.productName}
                              </h3>
                              {/* <Badge
                                className={
                                  "badge-soft-success rounded-pill ms-3 px-3 py-1"
                                }
                                style={{ fontSize: "0.7rem" }}
                              >
                                {"Rs. " + productDetails?.price}
                              </Badge> */}
                              {/* <Row> */}
                              <Col className="d-flex justify-content-end align-items-end p-0">
                                <Button
                                  type="button"
                                  className="btn btn-info"
                                  onClick={(e) => {
                                    props.history.push({
                                      pathname:
                                        "/product/edit/" +
                                        props.match.params.id,
                                      state: { productData: productDetails },
                                    });
                                  }}
                                >
                                  <i className="bx bx-pencil" />
                                </Button>
                                &nbsp;&nbsp;
                                <Button
                                  type="button"
                                  onClick={() => deleteProduct()}
                                  // color="danger"
                                  className="btn btn-danger m"
                                >
                                  <i className="bx bx-trash" />
                                </Button>
                              </Col>
                              {/* </Row> */}
                            </Card.Body>

                            {/* <Card.Body className="row">
                              <Col className="col-lg-6 col-md-6 col-sm-12">
                                <Row className="form-group">
                                  <label
                                    style={{ paddingTop: "inherit" }}
                                    className="col-sm-6 col-form-label font-weight-bolder"
                                  >
                                    Benifits Of Product
                                  </label>
                                  <Col sm={12}>
                                    <span style={{color:"#525252"}} dangerouslySetInnerHTML = {{ __html: productDetails.benifitsOfProduct }} />
                                  </Col>
                                </Row>
                              </Col>
                            </Card.Body> */}
                          </Card>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                          <Card>
                            <Card.Body className="d-flex align-items-center justify-content-between">
                              <h5 className="mb-0">Product Prices</h5>
                            </Card.Body>
                            <Card.Body>
                              {productDetails?.price &&
                                Object.keys(productDetails.price).map(
                                  (currency, index) => {
                                    return (
                                      <Row
                                        key={currency + index}
                                        className="form-group"
                                      >
                                        <label
                                          style={{ paddingTop: "inherit" }}
                                          className="col-sm-3 col-form-label font-weight-bolder"
                                        >
                                          {currency}
                                        </label>
                                        <Col sm={6}>
                                          {productDetails?.price[currency] ??
                                            "-"}
                                        </Col>
                                      </Row>
                                    );
                                  }
                                )}
                            </Card.Body>
                          </Card>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12">
                          <Card>
                            <Card.Body className="d-flex align-items-center justify-content-between">
                              <h5 className="mb-0">Product Specification</h5>
                            </Card.Body>
                            <Card.Body>
                              <Row className="form-group">
                                <label
                                  style={{ paddingTop: "inherit" }}
                                  className="col-sm-3 col-form-label font-weight-bolder"
                                >
                                  Product Weight
                                </label>
                                <Col sm={6}>
                                  {productDetails?.specification?.weight ?? "-"}
                                </Col>
                              </Row>
                              <Row className="form-group">
                                <label
                                  style={{ paddingTop: "inherit" }}
                                  className="col-sm-3 col-form-label font-weight-bolder"
                                >
                                  Product Base Material
                                </label>
                                <Col sm={6}>
                                  {productDetails?.specification
                                    ?.baseMaterial || "-"}
                                </Col>
                              </Row>
                              <Row className="form-group">
                                <label
                                  style={{ paddingTop: "inherit" }}
                                  className="col-sm-3 col-form-label font-weight-bolder"
                                >
                                  Product Base Color
                                </label>
                                <Col sm={6}>
                                  {productDetails?.specification?.color || "-"}
                                </Col>
                              </Row>
                              <Row className="form-group">
                                <label
                                  style={{ paddingTop: "inherit" }}
                                  className="col-sm-3 col-form-label font-weight-bolder"
                                >
                                  Product Base Dimensions
                                </label>
                                <Col sm={6}>
                                  {productDetails?.specification?.dimensions ||
                                    "-"}
                                </Col>
                              </Row>
                              <Row className="form-group">
                                <label
                                  style={{ paddingTop: "inherit" }}
                                  className="col-sm-3 col-form-label font-weight-bolder"
                                >
                                  Product Base Model Name
                                </label>
                                <Col sm={6}>
                                  {productDetails?.specification?.modelName ||
                                    "-"}
                                </Col>
                              </Row>
                              <Row className="form-group">
                                <label
                                  style={{ paddingTop: "inherit" }}
                                  className="col-sm-3 col-form-label font-weight-bolder"
                                >
                                  Product Size
                                </label>
                                <Col sm={6}>
                                  {productDetails?.specification?.size || "-"}
                                </Col>
                              </Row>
                              <Row className="form-group">
                                <label
                                  style={{ paddingTop: "inherit" }}
                                  className="col-sm-3 col-form-label font-weight-bolder"
                                >
                                  Product Type
                                </label>
                                <Col sm={6}>
                                  {productDetails?.specification?.type || "-"}
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                          <Card>
                            <Card.Body className="d-flex align-items-center justify-content-between">
                              <h5 className="mb-0">Product Description</h5>
                            </Card.Body>
                            <Card.Body>
                              <Row className="form-group">
                                {productDetails.productDescription !== "" &&
                                productDetails.productDescription !==
                                  undefined ? (
                                  <span
                                    style={{ color: "#525252" }}
                                    dangerouslySetInnerHTML={{
                                      __html: productDetails.productDescription,
                                    }}
                                  />
                                ) : (
                                  <h6>No Data Found</h6>
                                )}
                              </Row>
                            </Card.Body>
                          </Card>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <Card>
                            <Card.Body className="d-flex align-items-center justify-content-between">
                              <h5 className="mb-0">Benifits Of Product</h5>
                            </Card.Body>
                            <Card.Body>
                              <Row className="form-group">
                                {productDetails.benifitsOfProduct !== "" &&
                                productDetails.benifitsOfProduct !==
                                  undefined ? (
                                  <span
                                    style={{ color: "#525252" }}
                                    dangerouslySetInnerHTML={{
                                      __html: productDetails.benifitsOfProduct,
                                    }}
                                  />
                                ) : (
                                  <h6>No Data Found</h6>
                                )}
                              </Row>
                            </Card.Body>
                          </Card>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <Card>
                            <Card.Body className="d-flex align-items-center justify-content-between">
                              <h5 className="mb-0">How To Wear</h5>
                            </Card.Body>
                            <Card.Body>
                              <Row className="form-group">
                                {productDetails.howToWear !== "" &&
                                productDetails.howToWear !== undefined ? (
                                  <span
                                    style={{ color: "#525252" }}
                                    dangerouslySetInnerHTML={{
                                      __html: productDetails.howToWear,
                                    }}
                                  />
                                ) : (
                                  <h6>No Data Found</h6>
                                )}
                              </Row>
                            </Card.Body>
                          </Card>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                          <Card>
                            <Card.Body className="d-flex align-items-center justify-content-between">
                              <h5 className="mb-0">Other Description</h5>
                            </Card.Body>
                            <Card.Body>
                              <Row className="form-group">
                                {productDetails.otherDescription !== "" &&
                                productDetails.otherDescription !==
                                  undefined ? (
                                  <span
                                    style={{ color: "#525252" }}
                                    dangerouslySetInnerHTML={{
                                      __html: productDetails.otherDescription,
                                    }}
                                  />
                                ) : (
                                  <h6>No Data Found</h6>
                                )}
                              </Row>
                            </Card.Body>
                          </Card>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <Card>
                            <Card.Body className="d-flex align-items-center justify-content-between">
                              <h5 className="mb-0">Legel Desclaimer</h5>
                            </Card.Body>
                            <Card.Body>
                              <Row className="form-group">
                                {productDetails.legelDesclaimer !== "" &&
                                productDetails.legelDesclaimer !== undefined ? (
                                  <span
                                    style={{ color: "#525252" }}
                                    dangerouslySetInnerHTML={{
                                      __html: productDetails.legelDesclaimer,
                                    }}
                                  />
                                ) : (
                                  <h6>No Data Found</h6>
                                )}
                              </Row>
                            </Card.Body>
                          </Card>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <Card>
                            <Card.Body className="d-flex align-items-center justify-content-between">
                              <h5 className="mb-0">Safety Info</h5>
                            </Card.Body>
                            <Card.Body>
                              <Row className="form-group">
                                {productDetails.legelDesclaimer !== "" &&
                                productDetails.legelDesclaimer !== undefined ? (
                                  <span
                                    style={{ color: "#525252" }}
                                    dangerouslySetInnerHTML={{
                                      __html: productDetails.safetyInfo,
                                    }}
                                  />
                                ) : (
                                  <h6>No Data Found</h6>
                                )}
                              </Row>
                            </Card.Body>
                          </Card>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="2">
              <Row>
                <Col className="col-12">
                  <Card>
                    <CardBody>
                      <h5>Product Photos</h5>
                      <Card>
                        <Card.Body>
                          <div className="row">
                            <div className="col">
                              {productDetails.productImages &&
                              productDetails.productImages.length > 0 ? (
                                <>
                                  <Row className="p-3">
                                    <h5>Product Images</h5>
                                  </Row>
                                  <Row>
                                    {productDetails.productImages.map(
                                      (item, key) => (
                                        <div
                                          key={key}
                                          className="col-lg-4 col-md-auto col-sm-auto"
                                        >
                                          <Card className="m-0">
                                            <img
                                              style={{
                                                width: "100%",
                                                height: "250px",
                                                borderRadius: 10,
                                              }}
                                              className="responsive img-fluid wid-100"
                                              src={
                                                constant.IMG_URL +
                                                item.imagePath
                                              }
                                              alt="User"
                                            />
                                          </Card>
                                          <div className="m-2 text-right">
                                            <Button
                                              size="sm"
                                              variant="outline-danger"
                                              onClick={() => {
                                                deleteImage(item._id);
                                              }}
                                              style={{ cursor: "pointer" }}
                                            >
                                              <i
                                                style={{ marginRight: "4px" }}
                                                className="fas fa-trash"
                                              />
                                              Delete
                                            </Button>
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </Row>
                                </>
                              ) : null}
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-3">Product Images</CardTitle>
                      <Form>
                        <Dropzone
                          onDrop={(acceptedFiles) => {
                            handleAcceptedFiles(acceptedFiles);
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div
                                className="dz-message needsclick"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <div className="dz-message needsclick">
                                  <div className="mb-3">
                                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                                  </div>
                                  <h4>Drop files here or click to upload.</h4>
                                </div>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                        <div
                          className="dropzone-previews mt-3"
                          id="file-previews"
                        >
                          {roomImgs.map((f, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        alt={f.name}
                                        src={f.preview}
                                      />
                                    </Col>
                                    <Col>
                                      <Link
                                        to="#"
                                        className="text-muted font-weight-bold"
                                      >
                                        {f.name}
                                      </Link>
                                      <p className="mb-0">
                                        <strong>{f.formattedSize}</strong>
                                      </p>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            );
                          })}
                        </div>
                      </Form>
                      <hr />
                      <div
                        style={{ justifyContent: "center" }}
                        className="d-flex flex-wrap gap-2"
                      >
                        <Button
                          color="primary"
                          type="submit"
                          onClick={updateImages}
                        >
                          Save Changes
                        </Button>
                        <Button type="submit" color="secondary">
                          Cancel
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PropertyDetails;
