import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { logoutUser } from "../../store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { useUserDispatch } from "index";
import { removeRole } from "redux/roleSlice";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

const Logout = (props) => {
  toastr.options = {
    positionClass: "toast-top-right",
    timeOut: 1200,
    extendedTimeOut: 1000,
    closeButton: true,
    progressBar: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: "swing",
    hideEasing: "swing",
    showMethod: "fadeIn",
    hideMethod: "fadeOut",
    showDuration: 300,
    hideDuration: 500,
  };
  const dispatch = useUserDispatch();

  useEffect(() => {
    if (props.error) {
      toastr.error(props.error.message, props.error.title);
    }
    dispatch(removeRole());
    localStorage.clear();
    props.history.push("/login");
  }, []);

  return <></>;
};

Logout.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Logout);
