import React, { useState, useEffect, useRef, useMemo } from "react";
import { Link } from "react-router-dom";

import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardFooter,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  FormFeedback,
} from "reactstrap";
import Select from "react-select";
import Dropzone from "react-dropzone";
import { Formik, useFormik } from "formik";
import { AddProductSchema } from "service/validationSchema";
import JoditEditor from "jodit-react";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { ApiServices } from "service/apiServices";
import * as url from "../../service/url_helper";
import { Constants } from "helpers/constant";
import { submit } from "redux-form";
import { getCompressedImages } from "helpers/utils.js";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

const EditProduct = (props) => {
  toastr.options = {
    positionClass: "toast-top-right",
    timeOut: 1200,
    extendedTimeOut: 1000,
    closeButton: true,
    progressBar: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: "swing",
    hideEasing: "swing",
    showMethod: "fadeIn",
    hideMethod: "fadeOut",
    showDuration: 300,
    hideDuration: 500,
  };
  document.title = "Edit Product | Numerolution";
  const [productDetails, setProductDetails] = useState({
    ...props?.location?.state?.productData,
  });
  const [roomImgsLimit, setRoomImgsLimit] = useState();
  const [roomImgs, setRoomImgs] = useState([]);
  const [productDesctiption, setProductDescription] = useState({ value: "" });
  const [otherDescription, setOtherDescription] = useState({ value: "" });
  const [benifitsOfProduct, setBenifitsOfProduct] = useState({ value: "" });
  const [legelDesclaimer, setLegelDesclaimer] = useState({ value: "" });
  const [howToWear, sethowToWear] = useState({ value: "" });
  const [safetyInfo, setSafetyInfo] = useState({ value: "" });
  const [values, setValues] = useState({});
  const editor = useRef(null);
  const [categories, setCategories] = useState([]);

  const handleContent = (e, eventName) => {
    if (eventName === "productDescription") {
      productDesctiption.value = e;
    } else if (eventName === "otherDescription") {
      otherDescription.value = e;
    } else if (eventName === "benifitsOfProduct") {
      benifitsOfProduct.value = e;
    } else if (eventName === "howToWear") {
      howToWear.value = e;
    } else if (eventName === "legelDesclaimer") {
      legelDesclaimer.value = e;
    } else if (eventName === "safetyInfo") {
      safetyInfo.value = e;
    }
  };

  const handleChange = (e, key) => {
    let name = e?.target?.name;
    if (name === "productName") {
      setValues({ ...values, productName: e.target.value });
    }
    if (name === "price") {
      let oldPrices = values.price
        ? { ...values.price }
        : { ...productDetails.price };
      setValues({ ...values, price: { ...oldPrices, [key]: e.target.value } });
    }
    if (name === "weight") {
      setValues({ ...values, weight: e.target.value });
    }
    if (name === "dimensions") {
      setValues({ ...values, dimensions: e.target.value });
    }
    if (name === "modelName") {
      setValues({ ...values, modelName: e.target.value });
    }
    if (name === "type") {
      setValues({ ...values, type: e.target.value });
    }
    if (name === "color") {
      setValues({ ...values, color: e.target.value });
    }
    if (name === "baseMaterial") {
      setValues({ ...values, baseMaterial: e.target.value });
    }
    if (name === "size") {
      setValues({ ...values, size: e.target.value });
    }
    if (e.name === "category") {
      setValues({ ...values, category: e });
    }
  };

  const getAllCategory = () => {
    try {
      let uri = url.GET_ALL_CATEGORY;
      ApiServices.callServiceGet(uri)
        .then((response) => {
          if (response?.result?.type === Constants.SUCCESS) {
            let allCategories = response.result.data;
            setCategories(allCategories);
            if (!productDetails?.categoryId) return;
            setValues({
              category: allCategories.find(
                (item) => item._id === productDetails?.categoryId
              ),
            });
          }
        })
        .catch((error) => console.log("catch getAllCategory", error));
    } catch (error) {
      console.log("catch getAllCategory", error);
    }
  };
  const updateProduct = (e) => {
    e.preventDefault();
    let productObj = {
      categoryId:
        values?.category?._id == undefined
          ? productDetails?.categoryId
            ? productDetails?.categoryId
            : null
          : values.category._id,
      productName:
        values.productName == undefined
          ? productDetails.productName
          : values.productName,
      price: values.price == undefined ? productDetails.price : values.price,
      specification: {
        weight:
          values.weight == undefined
            ? productDetails.specification.weight
            : values.weight,
        dimensions:
          values.dimensions == undefined
            ? productDetails.specification.dimensions
            : values.dimensions,
        modelName:
          values.modelName == undefined
            ? productDetails.specification.modelName
            : values.modelName,
        type:
          values.type == undefined
            ? productDetails.specification.type
            : values.type,
        color:
          values.color == undefined
            ? productDetails.specification.color
            : values.color,
        baseMaterial:
          values.baseMaterial == undefined
            ? productDetails.specification.baseMaterial
            : values.baseMaterial,
        size:
          values.size == undefined
            ? productDetails.specification.size
            : values.size,
      },
      productDescription:
        productDesctiption.value == ""
          ? productDetails.productDesctiption
          : productDesctiption.value,
      otherDescription:
        otherDescription.value == ""
          ? productDetails.otherDescription
          : otherDescription.value,
      benifitsOfProduct:
        benifitsOfProduct.value == ""
          ? productDetails.benifitsOfProduct
          : benifitsOfProduct.value,
      legelDesclaimer:
        legelDesclaimer.value == ""
          ? productDetails.legelDesclaimer
          : legelDesclaimer.value,
      howToWear:
        howToWear.value == "" ? productDetails.howToWear : howToWear.value,
      safetyInfo:
        safetyInfo.value == "" ? productDetails.safetyInfo : safetyInfo.value,
    };
    const formData = new FormData();
    formData.append("data", JSON.stringify(productObj));

    let uri = url.UPDATE_PRODUCT + props.match.params.id;
    ApiServices.callServicePutWithFormData(uri, formData).then((response) => {
      if (response.result.type === "success") {
        toastr.success("Product updated successfully", "Success");
        setTimeout(() => {
          // window.location.replace('#/product/detail/' + props.match.params.id);
          // props.history.push('/product/detail/' + props.match.params.id)
          props.history.push({
            pathname: "/product/detail/" + props.match.params.id,
          });
        }, 1000);
      }
    });
  };
  const handleAcceptedFiles = async (files) => {
    await getCompressedImages(files)
      .then((compressedImgs) => {
        let newFiles = compressedImgs.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            formattedSize: formatBytes(file.size),
          })
        );
        if (newFiles.length === compressedImgs.length) {
          if (
            roomImgsLimit > 0 &&
            [...roomImgs, ...newFiles].length <= roomImgsLimit
          )
            return setRoomImgs([...roomImgs, ...newFiles]);
          toastr.error("Limit exceeded", "Error");
        }
      })
      .catch((error) => {
        console.log("error =>", error);
        toastr.error(error ?? "Error Occred while uploading images", "Error");
      });
  };

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  useEffect(() => {
    getAllCategory();
  }, []);
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Product" breadcrumbItem="Edit Product" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <CardTitle>Basic Information</CardTitle>
                  <CardSubtitle className="mb-4">
                    Fill all information below
                  </CardSubtitle>
                  <Form>
                    <Row className="mb-4">
                      <Label
                        htmlFor="billing-address"
                        md="2"
                        className="col-form-label"
                      >
                        Category
                        <span style={{ color: "red" }}> *</span>
                      </Label>
                      <Col md="10">
                        <Select
                          styles={{
                            menu: (provided) => ({
                              ...provided,
                              zIndex: 100,
                            }),
                          }}
                          name="category"
                          id="category"
                          placeholder="Select category"
                          value={values.category}
                          onChange={(e) =>
                            handleChange({ ...e, name: "category" })
                          }
                          options={categories}
                          getOptionLabel={(e) => e.categoryName}
                          getOptionValue={(e) => e.categoryName}
                          classNamePrefix="select2-selection"
                          closeMenuOnSelect={true}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="productName">Product Name</Label>
                          <Input
                            id="productName"
                            name="productName"
                            type="text"
                            onChange={handleChange}
                            //onBlur={handleBlur}
                            defaultValue={productDetails?.productName}
                            placeholder="Enter product name"
                            className="form-control"
                          />
                        </div>
                      </Col>
                      <Col sm="6">
                        <Row>
                          {productDetails?.price &&
                            Object.keys(productDetails.price).map(
                              (currency, index) => {
                                return (
                                  <Col key={currency + index}>
                                    <div className="mb-3">
                                      <Label htmlFor={`${currency}`}>
                                        {currency}
                                      </Label>
                                      <Input
                                        id={`${currency}`}
                                        name="price"
                                        type="text"
                                        onChange={(e) =>
                                          handleChange(e, currency)
                                        }
                                        //onBlur={handleBlur}
                                        defaultValue={
                                          productDetails?.price[currency] || ""
                                        }
                                        className="form-control"
                                        placeholder="Enter product price"
                                      />
                                    </div>
                                  </Col>
                                );
                              }
                            )}
                        </Row>
                      </Col>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="productdesc">
                            Product Description
                          </Label>
                          <JoditEditor
                            ref={editor}
                            name="productDescription"
                            id="productDescription"
                            // value={productDesctiption.value}
                            value={
                              productDesctiption?.value ||
                              productDetails?.productDescription ||
                              ""
                            }
                            onChange={(e) =>
                              handleContent(e, "productDescription")
                            }
                          />
                        </div>
                      </Col>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="productdesc">Other Description</Label>
                          <JoditEditor
                            ref={editor}
                            name="otherDescription"
                            id="otherDescription"
                            // value={otherDescription.value}
                            value={
                              otherDescription?.value ||
                              productDetails?.otherDescription ||
                              ""
                            }
                            onChange={(e) =>
                              handleContent(e, "otherDescription")
                            }
                          />
                        </div>
                      </Col>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="benifitsOfProduct">
                            Benifits Of Product
                          </Label>
                          <JoditEditor
                            ref={editor}
                            name="benifitsOfProduct"
                            id="benifitsOfProduct"
                            // value={benifitsOfProduct.value}
                            value={
                              benifitsOfProduct?.value ||
                              productDetails?.benifitsOfProduct ||
                              ""
                            }
                            onChange={(e) =>
                              handleContent(e, "benifitsOfProduct")
                            }
                          />
                        </div>
                      </Col>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="howToWear">How To Wear</Label>
                          <JoditEditor
                            ref={editor}
                            name="howToWear"
                            id="howToWear"
                            // value={howToWear.value}
                            value={
                              howToWear?.value ||
                              productDetails?.howToWear ||
                              ""
                            }
                            onChange={(e) => handleContent(e, "howToWear")}
                          />
                        </div>
                      </Col>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="howToWear">Legel Desclaimer</Label>
                          <JoditEditor
                            ref={editor}
                            name="legelDesclaimer"
                            id="legelDesclaimer"
                            // value={legelDesclaimer.value}
                            value={
                              legelDesclaimer?.value ||
                              productDetails?.legelDesclaimer ||
                              ""
                            }
                            onChange={(e) =>
                              handleContent(e, "legelDesclaimer")
                            }
                          />
                        </div>
                      </Col>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="safetyInfo">Safety Info</Label>
                          <JoditEditor
                            ref={editor}
                            name="safetyInfo"
                            id="safetyInfo"
                            // value={safetyInfo.value}
                            value={
                              safetyInfo?.value ||
                              productDetails?.safetyInfo ||
                              ""
                            }
                            onChange={(e) => handleContent(e, "safetyInfo")}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <CardTitle>Specifications</CardTitle>
                  <CardSubtitle className="mb-3">
                    Fill all information below
                  </CardSubtitle>
                  <Form>
                    <Row>
                      <Col sm={6}>
                        <div className="mb-3">
                          <Label htmlFor="weight">Weight</Label>
                          <Input
                            onChange={handleChange}
                            //onBlur={handleBlur}
                            id="weight"
                            name="weight"
                            defaultValue={
                              productDetails?.specification?.weight || ""
                            }
                            placeholder="Enter product weight"
                            type="text"
                            className="form-control"
                          />
                        </div>
                      </Col>
                      <Col sm={6}>
                        <div className="mb-3">
                          <Label htmlFor="dimensions">Dimensions</Label>
                          <Input
                            onChange={handleChange}
                            //onBlur={handleBlur}
                            id="dimensions"
                            name="dimensions"
                            defaultValue={
                              productDetails?.specification?.weight || ""
                            }
                            placeholder="Enter product dimensions"
                            type="text"
                            className="form-control"
                          />
                        </div>
                      </Col>
                      <Col sm={6}>
                        <div className="mb-3">
                          <Label htmlFor="modelName">Model Name</Label>
                          <Input
                            onChange={handleChange}
                            //onBlur={handleBlur}
                            id="modelName"
                            name="modelName"
                            type="text"
                            defaultValue={
                              productDetails?.specification?.modelName || ""
                            }
                            placeholder="Enter model name"
                            className="form-control"
                          />
                        </div>
                      </Col>
                      <Col sm={6}>
                        <div className="mb-3">
                          <Label htmlFor="type">Type</Label>
                          <Input
                            onChange={handleChange}
                            //onBlur={handleBlur}
                            id="type"
                            name="type"
                            type="text"
                            defaultValue={
                              productDetails?.specification?.type || ""
                            }
                            placeholder="Enter product type"
                            className="form-control"
                          />
                        </div>
                      </Col>
                      <Col sm={6}>
                        <div className="mb-3">
                          <Label htmlFor="color">Color</Label>
                          <Input
                            onChange={handleChange}
                            //onBlur={handleBlur}
                            id="color"
                            name="color"
                            defaultValue={
                              productDetails?.specification?.color || ""
                            }
                            placeholder="Enter product color"
                            type="text"
                            className="form-control"
                          />
                        </div>
                      </Col>
                      <Col sm={6}>
                        <div className="mb-3">
                          <Label htmlFor="baseMaterial">Base Material</Label>
                          <Input
                            onChange={handleChange}
                            //onBlur={handleBlur}
                            id="baseMaterial"
                            name="baseMaterial"
                            type="text"
                            defaultValue={
                              productDetails?.specification?.baseMaterial || ""
                            }
                            placeholder="Enter product base material"
                            className="form-control"
                          />
                        </div>
                      </Col>
                      <Col sm={6}>
                        <div className="mb-3">
                          <Label htmlFor="size">Size</Label>
                          <Input
                            onChange={handleChange}
                            //onBlur={handleBlur}
                            id="size"
                            name="size"
                            defaultValue={
                              productDetails?.specification?.size || ""
                            }
                            placeholder="Enter product size"
                            type="text"
                            className="form-control"
                          />
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  {/* <CardTitle className="mb-3">Product Images</CardTitle> */}
                  {/* <Form>
                    <Dropzone
                      onDrop={(acceptedFiles) => {
                        handleAcceptedFiles(acceptedFiles);
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone">
                          <div
                            className="dz-message needsclick"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <div className="dz-message needsclick">
                              <div className="mb-3">
                                <i className="display-4 text-muted bx bxs-cloud-upload" />
                              </div>
                              <h4>Drop files here or click to upload.</h4>
                            </div>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    <div className="dropzone-previews mt-3" id="file-previews">
                      {roomImgs.map((f, i) => {
                        return (
                          <Card
                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                            key={i + "-file"}
                          >
                            <div className="p-2">
                              <Row className="align-items-center">
                                <Col className="col-auto">
                                  <img
                                    data-dz-thumbnail=""
                                    height="80"
                                    className="avatar-sm rounded bg-light"
                                    alt={f.name}
                                    src={f.preview}
                                  />
                                </Col>
                                <Col>
                                  <Link
                                    to="#"
                                    className="text-muted font-weight-bold"
                                  >
                                    {f.name}
                                  </Link>
                                  <p className="mb-0">
                                    <strong>{f.formattedSize}</strong>
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        );
                      })}
                    </div>
                  </Form> */}
                  {/* <hr /> */}
                  <div
                    style={{ justifyContent: "center" }}
                    className="d-flex flex-wrap gap-2"
                  >
                    <Button
                      color="primary"
                      type="submit"
                      onClick={updateProduct}
                    >
                      Update Changes
                    </Button>
                    <Button type="submit" color="secondary">
                      Cancel
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditProduct;
