import { isEmpty } from "lodash";
import { Constants } from "./constant";
import Compressor from "compressorjs";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import * as url from "service/url_helper";
import { ApiServices } from "service/apiServices";

export const isUserLoggedIn = () => {
  let token = getSecureToken(Constants.KEY_USER_TOKEN);
  let userData = getSecureToken(Constants.KEY_USER_DATA);
  if (isEmpty(token) && isEmpty(userData)) {
    return false;
  } else {
    return true;
  }
};

export const setSecureToken = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
};

export const getSecureToken = (key) => {
  const data = localStorage.getItem(key);
  if (data !== null) {
    return JSON.parse(data);
  } else {
    return false;
  }
};
export const getCompressedImages = (rawImgs) => {
  return new Promise((resolve, reject) => {
    if (rawImgs?.length > 0) {
      let updatedArr = [];
      rawImgs.map((image, index) => {
        new Compressor(image, {
          quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
          maxWidth: 1000,
          maxHeight: 1000,
          strict: true,
          success: (compressedResult) => {
            // compressedResult has the compressed file.
            // Use the compressed file to upload the images to your server.
            updatedArr.push(compressedResult);
            if (updatedArr.length === rawImgs.length) {
              return resolve(updatedArr);
            }
          },
          error: (error) => {
            return reject(error);
          },
        });
      });
    }
  });
};

export const DeleteModal = async (data, callBack) => {
  const Alert = withReactContent(Swal);
  try {
    const result = await Alert.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "##556ee6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });
    if (result.isConfirmed) {
      await callBack(data);
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const verifyUser = () => {
  return new Promise(async (resolve, reject) => {
    let userRole;
    //make api call here to check the domain and store it in session storage
    const userData = getSecureToken(Constants.KEY_USER_DATA);
    const uri = url.GET_ADMIN_BY_ID + userData._id;
    await ApiServices.callServiceGet(uri)
      .then((response) => {
        if (response.result.type === Constants.ERROR) {
          localStorage.clear();
          window.location.replace("/#/login");
          return reject("No admin found");
        }
        userRole = response.result.data.isAdmin
          ? "admin"
          : response.result.data.isAffiliate
          ? "affiliate"
          : null;
        if (userRole === null) {
          localStorage.clear();
          window.location.replace("/#/login");
          return reject("Unauthorized");
        }
      })
      .catch((error) => {
        console.log("verifyUser error", error);
      });
    return resolve(userRole);
  });
};
