//LOGIN
export const LOGIN_PASSWORD = "/auth-partner/login-password";
export const SEND_FEEDBACK = "/auth/contact-us";
export const LOGIN_ADMIN = "/auth/admin-login";
export const GET_ADMIN_BY_ID = "/admin/";

//Numerology Data
export const GET_NUM_DATA = "/admin/numerology-report";

// shop
export const PRODUCT_LIST = "/product";
export const CREATE_ORDER = "/order";

// product
export const ADD_PRODUCT = "/product";
export const GET_PRODUCT_BY_ID = "/product/";
export const GET_ALL_PRODUCTS = "/product";
export const UPDATE_PRODUCT = "/product/";
export const DELETE_PRODUCT_IMAGE = "/product/delete-image";

// category
export const GET_ALL_CATEGORY = "/category";

//Payment
export const INITIATE_PAYMENT = "/payment/pay";
export const INITIATE_PAYMENT_FOR_PDF = "/payment/pay-pdf";
export const GET_PAYMENT_FOR_PDF = "/payment/get-pdf";
export const GET_PAYMENT_STATUS = "/payment/get";

//Pdf
export const CREATE_AFFILIATE_USER = "/admin/affiliate";
export const GET_ALL_AFFILIATE = "/admin/affiliate";
export const UPDATE_AFFILIATE = "/admin/affiliate/";
//Pdf
export const CREATE_PDF_USER = "/pdf/create-user";
export const CREATE_PDF_ADMIN = "/pdf/generate-pdf-admin";
export const GET_ALL_PDF = "/pdf/getAll";

//Order
export const GET_ALL_ORDERS = "/order";
