import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import "./i18n";
import { Provider, createDispatchHook, createSelectorHook } from "react-redux";

import store from "./store";
import { userStore } from "redux/store";

export const userContext = React.createContext();

export const useUserDispatch = createDispatchHook(userContext);
export const useUserSelector = createSelectorHook(userContext);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider context={userContext} store={userStore}>
    <Provider store={store}>
      <React.Fragment>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </React.Fragment>
    </Provider>
  </Provider>
);

serviceWorker.unregister();
