import PropTypes, { func } from "prop-types";
import React, { useEffect, useState } from "react";
import { Row, Col, Card } from "react-bootstrap";
import {
  Container,
  CardBody,
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  FormGroup,
  Label,
  FormFeedback,
  Form,
  CardTitle,
  CardHeader,
} from "reactstrap";
import service from "service/constant";
import { Link, useParams } from "react-router-dom";
import toastr from "toastr";

//import action
import { getChartsData as onGetChartsData } from "../../store/actions";

//i18n
import { withTranslation } from "react-i18next";

import { useFormik } from "formik";
import * as Yup from "yup";
// import { setItem, getItem } from "service/utils";
import { getSecureToken, setSecureToken } from "helpers/utils.js";
import { Constants } from "helpers/constant.js";
import { ApiServices } from "service/apiServices";
import * as url from "service/url_helper";

import moment from "moment";

const AddEditAffiliate = (props) => {
  toastr.options = {
    positionClass: "toast-top-right",
    timeOut: 2000,
    extendedTimeOut: 1000,
    closeButton: true,
    progressBar: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: "swing",
    hideEasing: "swing",
    showMethod: "fadeIn",
    hideMethod: "fadeOut",
    showDuration: 300,
    hideDuration: 500,
  };
  const { id } = useParams();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: "",
      mobileNumber: "",
      discount: "",
      discountCode: "",
      email: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please enter name"),
      mobileNumber: id
        ? Yup.string()
        : Yup.string().required("Please enter mobile number"),
      discount: Yup.number().required("Please enter discount percentage"),
      discountCode: Yup.string().required("Please enter discount code"),
      email: id
        ? Yup.string()
        : Yup.string()
            .required("Please enter email address")
            .email("Not a valid email address"),
    }),
    onSubmit: addUpdateAffiliate,
  });

  async function addUpdateAffiliate(values) {
    try {
      values.discountCode = values.discountCode.toUpperCase();
      let uri = id ? url.UPDATE_AFFILIATE + id : url.CREATE_AFFILIATE_USER;
      const response = id
        ? await ApiServices.callServicePutWithBodyData(uri, values)
        : await ApiServices.callServicePostWithBodyData(uri, values);
      if (response && response?.result?.type === Constants.SUCCESS) {
        toastr.success(
          `Affiliate User ${id ? "Updated" : "Created"} Successfully`
        );
        props.history.push("/affiliate");
        return;
      }
      toastr.error(response.result?.message ?? "Error Occured", "Error");
    } catch (error) {
      toastr.error("Unknown error occured", "Error");
      console.log("addUpdateAffiliate Error", error);
    }
  }

  async function getAffiliateById(values) {
    try {
      let uri = url.UPDATE_AFFILIATE + id;
      await ApiServices.callServiceGet(uri).then((response) => {
        if (response.result.type === Constants.SUCCESS) {
          validation.setValues(response?.result?.data);
          return;
        }
        toastr.error(response.result?.message ?? "Error Occured", "Error");
      });
    } catch (error) {
      console.log("onSubmit Error", error);
    }
  }

  useEffect(() => {
    if (id) {
      getAffiliateById();
    }
  }, []);

  //meta title
  document.title = `${id ? "Edit" : "Add"} Affiliate | Numerolution`;

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Row className="px-4">
            <Col lg="12" className="my-2">
              <Card
                className="mb-0 mt-5 mt-lg-0"
                style={{
                  boxShadow: "0px 0px 10px 2px #62ffca",
                  opacity: "0.95",
                  borderRadius: "10px",
                }}
              >
                <CardBody>
                  <CardTitle className="my-2">
                    {id ? "Edit" : "Add"} Affiliate User
                  </CardTitle>
                  <div className="mt-3">
                    <Form
                      className="needs-validation"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <Row>
                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="validationCustom01">
                              Name <sup className="text-danger">*</sup>
                            </Label>
                            <Input
                              name="name"
                              placeholder="Name"
                              type="text"
                              className="form-control"
                              id="validationCustom01"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.name || ""}
                              invalid={
                                validation.touched.name &&
                                validation.errors.name
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.name &&
                            validation.errors.name ? (
                              <FormFeedback type="invalid">
                                {validation.errors.name}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>
                        {!id && (
                          <Col md="6">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom02">
                                Mobile number{" "}
                                <sup className="text-danger">*</sup>{" "}
                              </Label>
                              <Input
                                name="mobileNumber"
                                placeholder="Mobile number"
                                type="number"
                                className="form-control"
                                id="validationCustom02"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.mobileNumber || ""}
                                invalid={
                                  validation.touched.mobileNumber &&
                                  validation.errors.mobileNumber
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.mobileNumber &&
                              validation.errors.mobileNumber ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.mobileNumber}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                        )}
                      </Row>
                      <Row>
                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="validationCustom02">
                              Discount (%) <sup className="text-danger">*</sup>{" "}
                            </Label>
                            <Input
                              name="discount"
                              placeholder="Discount"
                              type="number"
                              className="form-control"
                              id="validationCustom02"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.discount || ""}
                              invalid={
                                validation.touched.discount &&
                                validation.errors.discount
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.discount &&
                            validation.errors.discount ? (
                              <FormFeedback type="invalid">
                                {validation.errors.discount}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="validationCustom02">
                              Discount Code <sup className="text-danger">*</sup>{" "}
                            </Label>
                            <Input
                              name="discountCode"
                              placeholder="Discount Code"
                              type="text"
                              className="form-control text-uppercase"
                              id="validationCustom02"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.discountCode || ""}
                              invalid={
                                validation.touched.discountCode &&
                                validation.errors.discountCode
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.discountCode &&
                            validation.errors.discountCode ? (
                              <FormFeedback type="invalid">
                                {validation.errors.discountCode}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>
                      </Row>
                      {!id && (
                        <>
                          <Row>
                            <Col md="12">
                              <FormGroup className="mb-3">
                                <Label htmlFor="validationCustom04">
                                  Email <sup className="text-danger">*</sup>
                                </Label>
                                <Input
                                  name="email"
                                  placeholder="Email"
                                  type="email"
                                  className="form-control"
                                  id="validationCustom04"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.email || ""}
                                  invalid={
                                    validation.touched.email &&
                                    validation.errors.email
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.email &&
                                validation.errors.email ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.email}
                                  </FormFeedback>
                                ) : null}
                              </FormGroup>
                            </Col>
                          </Row>
                          <CardHeader>
                            Note : <strong>Mobile Number</strong> will be
                            default password
                          </CardHeader>
                        </>
                      )}
                      <div className="text-center mt-4">
                        <Button color="success" type="submit">
                          Submit
                        </Button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AddEditAffiliate;
