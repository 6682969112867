import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { isEmpty, map } from "lodash";
import * as moment from "moment";
import {
  Badge,
  Button,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Dropdown,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Component
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";

//Import Image
import images from "assets/images";
import companies from "assets/images/companies";
import BLIV_Partner from "assets/images/logo-small-nobg_new.png";

import * as url from "service/url_helper";
import { ApiServices } from "service/apiServices.js";
import { getSecureToken } from "helpers/utils.js";
import { Constants } from "helpers/constant.js";
import PDFUserTable from "./PDFUserTable";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

const PDFUserList = (props) => {
  //meta title
  toastr.options = {
    positionClass: "toast-top-right",
    timeOut: 1200,
    extendedTimeOut: 1000,
    closeButton: true,
    progressBar: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: "swing",
    hideEasing: "swing",
    showMethod: "fadeIn",
    hideMethod: "fadeOut",
    showDuration: 300,
    hideDuration: 500,
  };
  document.title = "PDF User List | Numerolution";
  let userData = getSecureToken(Constants.KEY_USER_DATA);
  const [product, setProject] = useState();
  const [modal, setModal] = useState(false);
  const [todayOnly, setTodayOnly] = useState(false);
  const [userList, setUserList] = useState([]);
  const [exportingData, setExportingData] = useState([]);
  const [reset, setReset] = useState(Date.now());
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedPaymentSource, setSelectedPaymentSource] = useState("");
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  //delete order
  const [deleteModal, setDeleteModal] = useState(false);
  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };
  const capitalizeFirstLetter = (string) => {
    if (!string || string?.length == 0) return "N/A";
    return string?.charAt(0).toUpperCase() + string?.slice(1);
  };
  const columns = [
    {
      name: <span className="fw-bold font-size-14">Payment Id</span>,
      selector: (row) => capitalizeFirstLetter(row.paymentOrderId) ?? "N/A",
      sortable: true,
    },
    {
      name: <span className="fw-bold font-size-14">Customer Name</span>,
      selector: (row) => row.name ?? "N/A",
      cell: (row) => (
        <p className="text-capitalize text-truncate font-size-14 text-dark fw-bold mb-0">
          {capitalizeFirstLetter(row.name) ?? "N/A"}
        </p>
      ),
      sortable: true,
    },
    {
      name: <span className="fw-bold font-size-14">Phone Number</span>,
      selector: (row) => (row.contact ? row.contact : "N/A"),
      sortable: true,
    },
    {
      name: <span className="fw-bold font-size-14">Date of Inquire</span>,
      selector: (row) => row.createdAt,
      cell: (row) => handleValidDate(row.createdAt) ?? "N/A",
      sortable: true,
    },
    {
      name: <span className="fw-bold font-size-14">Birth Date</span>,
      selector: (row) => row.dob ?? "N/A",
      cell: (row) => handleValidDate(row.dob) ?? "N/A",
      sortable: true,
    },
    {
      name: <span className="fw-bold font-size-14">Language Selected</span>,
      selector: (row) => capitalizeFirstLetter(row.language) ?? "N/A",
      sortable: true,
    },
    {
      name: <span className="fw-bold font-size-14">Payment Source</span>,
      selector: (row) =>capitalizeFirstLetter(row.paymentSource)==='N/A' ?"Cash Free":capitalizeFirstLetter(row.paymentSource),
      cell: (row) => (
        <div className="d-flex flex-column align-items-center">
          <span>{capitalizeFirstLetter(row.paymentSource)==='N/A' ?"Cash Free":capitalizeFirstLetter(row.paymentSource) }</span>
          <span className={`${row.isPaid ? "text-success" : "text-danger"}`}>
            {row.isPaid ? "Paid" : "Not Paid"}
          </span>
        </div>
      ),
      sortable: true,
    },
    {
      name: <span className="fw-bold font-size-14">Download Status</span>,
      selector: (row) => (row.isDownloaded ? "Yes" : "No"),
      sortable: true,
    },
  ];
  function convertArrayOfObjectsToCSV() {
    let newArray = [...exportingData].map((item) => {
      let newItem = {
        "Customer Name": item.name,
        "Phone Number": item.contact,
        "Date of Inquire": handleValidDate(item.createdAt),
        "Birth Date": handleValidDate(item.dob),
        "Language Selected": capitalizeFirstLetter(item.language),
        "Download Status": item.isDownloaded ? "Yes" : "No",
        "Payment Source": item.paymentSource,
      };
      return newItem;
    });
    let result;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(newArray[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    newArray.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }
  function downloadCSV() {
    if (exportingData?.length <= 0) return toastr.error("No data", "Failed");
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV();
    if (csv == null) return;

    const filename = `PDF-USERS-${moment(Date.now()).format(
      "DD-MM-YYYY-HH-mm-ss"
    )}.csv`;

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }
  const onClickDelete = (product) => {
    setProject(product);
    setDeleteModal(true);
  };

  const handleDeleteOrder = () => {
    setDeleteModal(false);
  };

  const getPdfUsers = () => {
    try {
      let uri = url.GET_ALL_PDF;
      let reqObj = {
        todayOnly,
        paymentSource: selectedPaymentSource,
      };
      ApiServices.callServicePostWithBodyData(uri, reqObj)
        .then((response) => {
          if (response?.result?.type === "success") {
            setUserList(response.result.data);
            setExportingData(response.result.data);
          }
        })
        .catch((error) => {
          console.log("GET_PROPERTIES_BY_PARTNERID API call Error", error);
        });
    } catch (error) {
      console.log("GET_PROPERTIES_BY_PARTNERID API Function Error", error);
    }
  };
  useEffect(() => {
    getPdfUsers();
  }, [todayOnly, selectedPaymentSource]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row className="d-flex bg-white p-2 justify-content-between align-items-center w-100 m-0 mb-2">
            <Col className="p-0">
              <h5 className="mb-0 fw-bold">PDF User List</h5>
              <br />
              <div className="d-flex flex-row align-items-center gap-1">
                {/* <h6 className={`mb-0 text-${todayOnly ? "primary" : "black"}`}>Today</h6> */}
                <div className="square-switch">
                  <input
                    type="checkbox"
                    id="square-switch1"
                    switch="none"
                    onClick={() => {
                      setTodayOnly(!todayOnly);
                      setReset(Date.now());
                    }}
                  />
                  <label
                    className="mb-0"
                    htmlFor="square-switch1"
                    id="square-switch-filter"
                    data-on-label="Today"
                    data-off-label=""
                  />
                </div>
              </div>
            </Col>

            <Col className="d-flex justify-content-end align-items-center p-0 d-print-none">
              <Dropdown
                className="me-2"
                isOpen={dropdownOpen}
                toggle={toggle}
                direction={"down"}
              >
                <DropdownToggle caret color="success">
                  {selectedPaymentSource || "Payment Source"}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => setSelectedPaymentSource("")}>
                    All
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => setSelectedPaymentSource("Paypal")}
                  >
                    Paypal
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => setSelectedPaymentSource("Cash Free")}
                  >
                    Cash Free
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
              <Button
                type="button"
                color="success"
                className="btn"
                onClick={() => window.print()}
              >
                <i className="fa fa-print me-2" />
                Print All Data
              </Button>
              <Button
                type="button"
                color="success"
                className="btn ms-2"
                onClick={() => downloadCSV()}
              >
                <i className="fas fa-file-csv me-2" />
                Export CSV
              </Button>
            </Col>
          </Row>
          <Row className="bg-white">
            <Col lg="12" className="px-0 py-2">
              {userList.length > 0 ? (
                <PDFUserTable
                  {...props}
                  columns={columns}
                  reset={reset}
                  setExportingData={setExportingData}
                  userList={userList}
                />
              ) : (
                <Row>
                  <Col xs="12">
                    <div className="text-center my-3">
                      <p to="#" className="text-primary">
                        <i className="mdi mdi-alert-circle-outline me-2" />
                        No data
                      </p>
                    </div>
                  </Col>
                </Row>
              )}
              {/* <div>
                <div className="table-responsive">
                  <Table className="table-nowrap align-middle table-borderless">
                    <thead>
                      <tr style={{borderBottom : '2px solid #666'}}>
                        <th scope="col" style={{ width: "100px"}}>
                          #
                        </th>
                        <th scope="col" >Customer Name</th>
                        <th scope="col" >Phone Number</th>
                        <th scope="col" >Date of Inquire</th>
                        <th scope="col" >Birth Date</th>
                        <th scope="col" >Language</th>
                      </tr>
                    </thead>
                    <tbody>
                      {map(userList, (user, index) => (
                        <tr key={index} style={{borderBottom : '1px solid lightgray'}}>
                            <td>{index+1}</td>
                          <td>
                            <h5 className="text-truncate font-size-14 text-dark fw-bold mb-0">
                                {user?.name ?? "NA"}
                            </h5>
                          </td>
                          <td > {user?.contact ?? "NA"}</td>
                          <td> {user?.createdAt ? handleValidDate(user.createdAt) : "NA"}</td>
                          <td> {user?.dob ? handleValidDate(user.dob) : "NA"}</td>
                          <td> {user?.language ?? "NA"}</td>
                          
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div> */}
            </Col>
          </Row>

          {/* <Row>
            <Col xs="12">
              <div className="text-center my-3">
                <Link to="#" className="text-success">
                  <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                  Load more
                </Link>
              </div>
            </Col>
          </Row> */}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(PDFUserList);
